<template>
    <div>
        <!-- modal -->
        <matter-form ref="lawMatterForm"></matter-form>   
        <b-modal id="clientsReportsFormModal" ref="clientsReportsFormModal" centered size="xl" no-close-on-backdrop :title="$t('clientsReports.info')" hide-footer>
        <!-- table -->
        <vue-good-table
        id="clientsReportsFormTable" ref="clientsReportsFormTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize"
        :select-options="{ enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class', selectionText: 'rows selected', clearSelectionText: 'clear',
            disableSelectInfo: true, selectAllByGroup: true}" 
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :pagination-options="{ enabled: true, perPage:pageLength }">
        <div slot="emptystate"> {{ $t('dataTable.data_not_found') }} </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span  v-if="props.column.field ==='lawMatterNo'" class="text-nowrap">
            {{ $t('clientsReports.no') }}
          </span>
          <span  v-else-if="props.column.field ==='lawMatterTypeId'" class="text-nowrap">
            {{ $t('clientsReports.id') }}
          </span>
          <span  v-if="props.column.field ==='name'" class="text-nowrap">
            {{ $t('clientsReports.name') }}
          </span>
          <span  v-if="props.column.field ==='agent'" class="text-nowrap">
            {{ $t('clientsReports.agent') }}
          </span>
          <span  v-if="props.column.field ==='client'" class="text-nowrap">
            {{ $t('clientsReports.client') }}
          </span>
          <span  v-if="props.column.field ==='lawMatterStatus'" class="text-nowrap">
            {{ $t('clientsReports.status') }}
          </span>
          <span  v-if="props.column.field ==='lawMatterType'" class="text-nowrap">
            {{ $t('clientsReports.type') }}
          </span>
        </template>

        <!-- Row: Table Row -->
        <template slot="table-row" slot-scope="props" >
                <span v-if="props.column.field === 'lawMatterNo'" class="text-nowrap">
                    <b-badge variant="primary" style="cursor: pointer;" @click="showLawMatterForm(props.row)">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.lawMatterNo }}</span>
                    </b-badge>
                </span>
            </template>    

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                </span>
                <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
      </vue-good-table>
    </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getLawCourtList: {
                type: Function
            },
        },
        data() {
            return {
                total: 0,
                pageLength: 50,
                dir: false,
                pageNo: 1,
                columns: [
                    {
                        label: this.$t('clientsReports.no'),
                        field: 'lawMatterNo',
                    },
                    {
                        label: this.$t('clientsReports.client'),
                        field: 'client',
                    },
                    {
                        label: this.$t('clientsReports.agent'),
                        field: 'agent',
                    },
                    {
                        label: this.$t('clientsReports.type'),
                        field: 'lawMatterType',
                    },
                    {
                        label: this.$t('clientsReports.name'),
                        field: 'name',
                    },
                    {
                        label: this.$t('clientsReports.status'),
                        field: 'lawMatterStatus',
                    }                    
                ],
                rows: [],
                searchTerm: '',
                name:'',
            }
        },
        methods: {
            clear() {
                this.lawMatterCaseTypeSelect = 0;
                this.lawCourtTypeSelect = 0;
                this.name = '';
                this.type = 0;
            },
            showInfo(agentOwnerSelect, lawMatterTypeId, id) {
                this.$refs['clientsReportsFormModal'].show()
                let data = null
                
                if(agentOwnerSelect == 2)
                    data = { lawClientId: id, lawMatterTypeId: lawMatterTypeId, agentOwnerSelect: agentOwnerSelect }
                else
                    data = { clientAccountingId: id, lawMatterTypeId: lawMatterTypeId, agentOwnerSelect: agentOwnerSelect }

                const controller = { name: 'LawClient', actionName: 'GetLawClientLawMatterReportList'}
                const payload = { data: data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.rows = response.data.lawClientLawMatterReportList
                    } else {
                        this.$bvModal.hide('clientsReportsFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            showLawMatterForm(row) {
                this.$refs.lawMatterForm.showInfo(row.lawMatterId)            
            },
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    </style>
    