<template>
<div>
    <b-modal id="employeeFormModal" centered size="xl" no-close-on-backdrop ref="employeeFormModal" :title="$t('employee.info')" hide-footer>
        <b-card>
            <employee-photo-cropper-form :refreshEmployeePhoto="refreshEmployeePhoto" :image="image" :employeeId="this.id" :uploadImage="uploadImage" :employeePhotoCropperForm="employeePhotoCropperForm"></employee-photo-cropper-form>
            <b-form :hidden="hiddenEmployeePhoto">
                <b-row>
                    <b-col>
                        <b-img v-b-tooltip.html :title="$t('employee.change')" style="cursor: pointer;" :src="imageSrc" v-bind="mainProps" onclick="document.getElementById('photoSelect').click()" rounded="circle" alt="Circle image" class="d-inline-block mb-1" />
                    </b-col>
                    <b-col>
                        <input hidden id="photoSelect" type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
                    </b-col>
                </b-row>
            </b-form>
            <b-form>
                    <b-row>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                    <label>{{ $t('employee.name')  }}</label>
                                    <b-form-input disabled size="sm" v-model="name" :placeholder="$t('employee.name')" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                    <label>{{ $t('employee.surname')  }}</label>
                                    <b-form-input disabled size="sm" v-model="surname" :placeholder="$t('employee.surname')" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                    <label>{{ $t('employee.email')  }}</label>
                                    <b-form-input disabled size="sm" v-model="email" :placeholder="$t('employee.email')" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                    <label>{{ $t('employee.title')  }}</label>
                                    <b-form-select disabled size="sm" v-model="titleSelect" value-field="id" text-field="name" :options="titleOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group disabled>
                                <label for="starting-date-input">{{ $t('employee.starting_date') }}</label>
                                <CustomDatePicker disabled :datePickerValidateOption="1" v-model="startingDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group disabled>
                                <label for="ending-date-input">{{ $t('employee.ending_date') }}</label>
                                <CustomDatePicker disabled :datePickerValidateOption="0" v-model="endingDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col">
                            <b-form-group>
                                    <label>{{ $t('employee.department')  }}</label>
                                    <b-form-select disabled size="sm" v-model="departmentSelect" value-field="id" text-field="name" :options="departmentOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                    <label>{{ $t('employee.role')  }}</label>
                                    <b-form-select disabled size="sm" v-model="roleSelect" value-field="id" text-field="name" :options="roleOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                <label>{{ $t('employee.classification')  }}</label>
                                <b-form-select disabled size="sm" v-model="classificationSelect" value-field="id" text-field="name" :options="classificationOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                    <label>{{ $t('employee.position')  }}</label>
                                    <b-form-select disabled size="sm" v-model="positionSelect" value-field="id" text-field="name" :options="positionOption" />
                            </b-form-group>
                        </b-col>
                        <b-col :hidden="hiddenPermissionSelect" class="col">
                            <b-form-group>
                                <label>{{ $t('employee.permission_template')  }}</label>
                                <b-form-select disabled size="sm" v-model="permissionTemplateSelect" value-field="id" text-field="name" :options="permissionTemplateOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form>
                                <label for="employee-national_id_number-input">{{ $t('employee.national_id_number') }}</label>
                                <b-form-group>
                                    <b-form-input disabled id="employee-national_id_number-input" v-model="nationalIDNumber" maxlength="11" type="number" size="sm" />
                                </b-form-group>
                            </b-form>
                        </b-col>

                        <b-col class="col">
                            <b-form-group disabled>
                                <label for="employee-birth-date-input">{{ $t('employee.birth_date') }}</label>
                                <CustomDatePicker disabled :datePickerValidateOption="0" id="employee-birth-date-input" v-model="dateOfBirth"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                            <b-col class="col-md-4">
                                <b-form>
                                    <label for="employee-social-working-number-input">{{ $t('employee.sgk_no') }}</label>
                                    <b-form-group>
                                        <b-form-input disabled id="employee-social-working-number-input" v-model="socialWorkingNumber" maxlength="13" type="number" size="sm" />
                                    </b-form-group>
                                </b-form>
                            </b-col>
                            <b-col class="col-md-4">
                                <b-form>
                                    <label>{{ $t('employee.annual_leave_right')  }}</label>
                                    <b-form-select disabled size="sm" v-model="annualLeaveRightSelect" value-field="id" text-field="explanation" :options="annualLeaveRightOption" />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employee-is-lawyer-input">{{ $t('employee.is_lawyer') }}</label>
                                <br>
                                <b-form-checkbox disabled class="custom-control-success" name="employee-is-lawyer-input" id="employee-is-lawyer-input" v-model="isLawyer" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col v-if="isLawyer == true" class="col-md-3 mt-1">
                                <b-form>
                                    <label for="employee-lawyer-no-input">{{ $t('employee.lawyer_no') }}</label>
                                    <b-form-group>
                                        <b-form-input disabled id="employee-lawyer-no-input" v-model="lawyerNo" maxlength="250" size="sm" />
                                    </b-form-group>
                                </b-form>
                            </b-col>
                            <b-col v-if="isLawyer == true" class="col-md-3 mt-1">
                                <label for="employee-lawyer-year-date-input">{{ $t('employee.lawyer_year') }}</label>
                                <b-form-group>
                                    <b-form-input disabled id="employee-lawyer-year-date-input" v-model="lawyerYear" type="number" maxlength="4" size="sm" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employee-is-retired-input">{{ $t('employee.is_retired') }}</label>
                                <br>
                                <b-form-checkbox disabled class="custom-control-success" name="employee-is-retired-input" id="employee-is-retired-input" v-model="isRetired" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col v-if="isRetired == true" class="col-md-3 mt-1">
                                <label for="employee-retired-year-date-input">{{ $t('employee.retired_year') }}</label>
                                <b-form-group>
                                    <b-form-input disabled id="employee-retired-year-date-input" v-model="retiredYear" type="number" maxlength="4" size="sm" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employee-is-law-input">{{ $t('employee.is_law') }}</label>
                                <br>
                                <b-form-checkbox disabled class="custom-control-success" name="employee-is-law-input" id="employee-is-law-input" v-model="isLaw" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
            </b-form>
        </b-card>
        <b-card :hidden="hiddenEmployeeControl">
            <b-form>
                <b-row>
                    <b-col class="mt-2">
                        <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                            <b-tab :title=" $t('employee.price_list')" active style="width:100%;">
                                <b-card-text>
                                    <employee-price-list :getEmployeeList="getEmployeeList" :employeeId="this.id"></employee-price-list>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-modal>
</div>
</template>

    
<script>
export default {
    props: {
        getEmployeeList: {
            type: Function
        }
    },
    data() {
        return {
            id: null,
            employeePhotoCropperForm: {},
            hiddenEmployeePhoto: true,
            hiddenPermissionSelect: true,
            hiddenEmployeeControl: true,
            name: '',
            surname: '',
            email: '',
            titleSelect: 0,
            titleOption: [],
            departmentSelect: 0,
            departmentOption: [],
            roleSelect: 0,
            roleOption: [],
            classificationSelect: 0,
            classificationOption: [],
            positionSelect: 0,
            positionOption: [],
            permissionTemplateSelect: 0,
            permissionTemplateOption: [],
            endingDate: null,
            startingDate: null,
            dateOfBirth: null,
            imageSrc: '',
            image: null,
            mainProps: {
                blank: false,
                blankColor: '#777',
                width: 75,
                height: 75,
                class: 'm1',
            },
            phoneNumber: 0,
            socialWorkingNumber: 0,
            nationalIDNumber: 0,
            foreignLanguage: '',
            isTranslatorSelect: 0,
            isTranslatorOption: [],
            languageItTranslates: '',
            isLawyer: false,
            isLaw: false,
            lawyerNo: 0,
            lawyerYear: 0,
            isRetired: false,
            retiredYear: 0,
            personToContactinCaseOfEmergency: '',
            graduatedSchool: '',
            graduatedDepartment: '',
            graduatedYear: '',
            master: false,
            masterDepartment: '',
            annualLeaveRightOption: [],
            annualLeaveRightSelect: 0,
        }
    },
    mounted() {
        this.refreshEmployeePhoto()
    },
    methods: {
        clear() {
            this.departmentSelect = 0
            this.roleSelect = 0
            this.permissionTemplateSelect = 0
            this.classificationSelect = 0
        },
        refreshEmployeePhoto(base64Image) {
            this.imageSrc = 'data:image/png;base64,' + base64Image
        },
        uploadImage(event) {
            this.$refs.employeePhotoCropperForm.showInfo()
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        showInfo(id) {
            this.$refs['employeeFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenPermissionSelect = false
                this.hiddenEmployeePhoto = true
                this.hiddenEmployeeControl = true
            } else {
                this.hiddenPermissionSelect = true
                this.hiddenEmployeePhoto = false
                this.hiddenEmployeeControl = false
                this.imageSrc = require('@/assets/images/logoUser.jpeg')
            }
            var data = {
                id: this.id
            }
            var controller = {
                name: 'Employee'
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.employee.name
                    this.surname = response.data.employee.surname
                    this.email = response.data.employee.email
                    this.titleOption = response.data.titleList
                    this.titleSelect = response.data.employee.titleId
                    this.titleOption.splice(0, 0, this.$nullSelected("name"))
                    this.departmentOption = response.data.departmentList
                    this.departmentSelect = response.data.employee.departmentId
                    this.departmentOption.splice(0, 0, this.$nullSelected("name"))
                    this.roleOption = response.data.employeeRoleList
                    this.roleSelect = response.data.employee.roleId
                    this.roleOption.splice(0, 0, this.$nullSelected("name"))
                    this.classificationOption = response.data.employeeClassificationList
                    this.classificationSelect = response.data.employee.classificationId
                    this.classificationOption.splice(0, 0, this.$nullSelected("name"))
                    this.positionOption = response.data.positionList
                    this.positionSelect = response.data.employee.positionId
                    this.positionOption.splice(0, 0, this.$nullSelected("name"))
                    this.permissionTemplateOption = response.data.permissionTemplateList
                    this.permissionTemplateSelect = response.data.employee.permissionTemplateId
                    this.permissionTemplateOption.splice(0, 0, this.$nullSelected("name"))
                    this.startingDate = response.data.employee.startingDate
                    this.endingDate = response.data.employee.endingDate
                    this.positionOption.splice(0, 0, this.$nullSelected("name"))
                    this.nationalIDNumber = response.data.employee.nationalIDNumber
                    this.socialWorkingNumber = response.data.employee.socialWorkingNumber
                    this.isRetired = response.data.employee.isRetired
                    this.retiredYear = response.data.employee.retirementYear
                    this.lawyerYear = response.data.employee.becomeLawyerYear
                    this.lawyerNo = response.data.employee.lawyerRegistryNumber
                    this.isLawyer = response.data.employee.isLawyer
                    this.isLaw = response.data.employee.isLaw
                    this.dateOfBirth = response.data.employee.dateOfBirth
                    this.annualLeaveRightOption = response.data.annualLeaveRightList
                    this.annualLeaveRightOption.splice(0, 0, this.$nullSelected("explanation"))
                    if (response.data.employee.annualLeaveRightId > 0) {
                        this.annualLeaveRightSelect = response.data.employee.annualLeaveRightId
                    } else {
                        this.annualLeaveRightSelect = 0
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    }
}
</script>

    
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
