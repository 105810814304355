<template>
<div>
    <!-- modal -->
    <b-modal id="addNewLawClientFormModal" centered size="lg" no-close-on-backdrop ref="addNewLawClientFormModal" :title=" $t('lawClient.client_to_add_as_law_client_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col>
                    <b-form>
                        <validation-provider :name=" $t('lawClient.stock_client')" #default="{ errors }" rules="required">
                            <b-form-group :label=" $t('lawClient.stock_client')" label-for="lawClient" :state="errors.length > 0 ? false:null">
                                <v-select id="lawClient" v-model="lawClientSelect" :options="lawClientOption" label="name" @search="onSearch">
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getClientsList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            lawClientSelect: '',
            lawClientOption: [],
        }
    },
    methods: {
        showInfo() {
            this.$refs['addNewLawClientFormModal'].show()
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    string: search
                }
                const controller = {
                    name: 'LawClient',
                    actionName: 'GetClientsToAddAsALawClient'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.lawClientOption = response.data.clientToAddAsLawClientList
                    loading(false)
                })
            }
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                clientId: this.lawClientSelect.clientId
            }
            const controller = {
                name: 'LawClient'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('addNewLawClientFormModal')
                            this.getClientsList()
                            this.$SaveAlert()
                            this.clear()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        clear () {
            this.lawClientSelect = ''
            this.lawClientOption = []
        },
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
