import i18n from "@/libs/i18n";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/Home",
      name: "Home",
      component: () => import("@/views/Home.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Clients",
      name: "Clients",
      component: () => import("@/views/pages/Clients/Clients.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Matter",
      name: "Matter",
      component: () => import("@/views/pages/Matter/Matter.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Invoice",
      name: "Invoice",
      component: () => import("@/views/pages/Finance/Invoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Payment",
      name: "Payment",
      component: () => import("@/views/pages/Finance/Payment.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Employee",
      name: "Employee",
      component: () => import("@/views/pages/Definitions/Employee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawClient",
      name: "LawClient",
      component: () => import("@/views/pages/Definitions/LawClient.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawActivity",
      name: "LawActivity",
      component: () => import("@/views/pages/Definitions/LawActivity.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawSubject",
      name: "LawSubject",
      component: () => import("@/views/pages/Definitions/LawSubject.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawDecision",
      name: "LawDecision",
      component: () => import("@/views/pages/Definitions/LawDecision.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawMatterOutcome",
      name: "LawMatterOutcome",
      component: () => import("@/views/pages/Definitions/LawMatterOutcome.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawMatterFileType",
      name: "LawMatterFileType",
      component: () =>
        import("@/views/pages/Definitions/LawMatterFileType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawMatterType",
      name: "LawMatterType",
      component: () => import("@/views/pages/Definitions/LawMatterType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawMatterStatus",
      name: "LawMatterStatus",
      component: () => import("@/views/pages/Definitions/LawMatterStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawCourt",
      name: "LawCourt",
      component: () => import("@/views/pages/Definitions/LawCourt.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/CreditNotes",
      name: "CreditNotes",
      component: () => import("@/views/pages/Reports/CreditNotes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/CancelledInvoice",
      name: "CancelledInvoice",
      component: () => import("@/views/pages/Reports/CancelledInvoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/CostAccounting",
      name: "CostAccounting",
      component: () => import("@/views/pages/Reports/CostAccounting.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Vouchers",
      name: "Vouchers",
      component: () => import("@/views/pages/Reports/Vouchers.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AgingReport",
      name: "AgingReport",
      component: () => import("@/views/pages/Reports/AgingReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MatterReport",
      name: "MatterReport",
      component: () => import("@/views/pages/Reports/MatterReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ExpenseReport",
      name: "ExpenseReport",
      component: () => import("@/views/pages/Reports/ExpenseReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceCodeReport",
      name: "InvoiceCodeReport",
      component: () => import("@/views/pages/Reports/InvoiceCodeReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawyerDailyworkReport",
      name: "LawyerDailyworkReport",
      component: () =>
        import("@/views/pages/Reports/LawyerDailyworkReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DailyworkReport",
      name: "DailyworkReport",
      component: () => import("@/views/pages/Dailywork/DailyworkReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawClientPowerOfAttorneyDeadlineReport",
      name: "LawClientPowerOfAttorneyDeadlineReport",
      component: () =>
        import("@/views/pages/Reports/PowerOfAttorneyReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ReminderReport",
      name: "ReminderReport",
      component: () => import("@/views/pages/Reminder/ReminderReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Account",
      name: "Account",
      component: () => import("@/views/pages/Account/Account.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawMatterCustomsRegistrationListOfLast1MonthExpirationDateReport",
      name: "LawMatterCustomsRegistrationListOfLast1MonthExpirationDateReport",
      component: () =>
        import(
          "@/views/pages/Reports/LawMatterCustomsRegistrationListOfLast1MonthExpirationDateReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawDocumentContentType",
      name: "LawDocumentContentType",
      component: () =>
        import("@/views/pages/Definitions/LawDocumentContentType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Expert",
      name: "Expert",
      component: () => import("@/views/pages/Expert/Expert.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawDailyworkDescription",
      name: "LawDailyworkDescription",
      component: () =>
        import("@/views/pages/Definitions/LawDailyworkDescription.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/StandardInvoiceCodes",
      name: "StandardInvoiceCodes",
      component: () =>
        import("@/views/pages/Definitions/StandardInvoiceCodes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceApprovalList",
      name: "InvoiceApprovalList",
      component: () => import("@/views/pages/Finance/InvoiceApprovalList.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LawNoteToBeOnInvoice",
      name: "LawNoteToBeOnInvoice",
      component: () => import("@/views/pages/Definitions/LawNoteToBeOnInvoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/ForgotPassword",
      name: "ForgotPassword",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
        adminAuth: true,
        residentAuth: false,
      },
    },
    {
      path: "/ChangePassword",
      name: "ChangePassword",
      component: () => import("@/views/ChangePassword.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/exit",
      name: "exit",
      component: () => import("@/views/Exit.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
      // redirect: '/'
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("accessToken");
  // to.meta.roles.includes(store.state.user.rol)
  if (to.meta.rule == "nonlogin") {
    next();
  } else if (to.meta.rule == "logged" && loggedIn) {
    const RoleId = localStorage.getItem("RoleId");
    if (to.meta.roleId != undefined) {
      if (to.meta.roleId == RoleId) {
        next();
      } else {
        return next("Unauthorized");
      }
    }
    next();
  } else {
    localStorage.clear();
    return next("/");
  }
});

export default router;
