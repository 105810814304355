<template>
<div>
    <dailywork-form :getDailyworkList="getDailyworkList" ref="dailyworkFormModal"></dailywork-form>
    <invoice-form :getDailyworkList="getDailyworkList" ref="invoiceForm"></invoice-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group class="d-flex align-items-center">
                    <b-form-checkbox v-model="notInvoiced" @input="changeNotInvoice()" name="check-button" switch inline>
                        {{ $t('dailywork.not_invoiced') }}
                    </b-form-checkbox>
                </b-form-group>
            </div>

            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" class="mr-1" variant="outline-primary" @click="getInfo(0)">{{ $t('dailywork.add') }}</b-button>
                        <b-button variant="outline-primary" size="sm" @click.prevent="showInvoiceForm()"> {{$t('dailywork.invoice')}} </b-button>
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="dailyworkTable" ref="dailyworkTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: this.checkedStatus,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" @on-selected-rows-change="handleSelectedRows" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'client'" class="text-nowrap">
                {{ $t('dailywork.client') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccounting'" class="text-nowrap">
                {{ $t('dailywork.client_accounting') }}
            </span>
            <span v-else-if="props.column.field === 'lawBillingStatus'" class="text-nowrap">
                {{ $t('dailywork.type') }}
            </span>
            <span v-else-if="props.column.field === 'paymentType'" class="text-nowrap">
                {{ $t('dailywork.payment_type') }}
            </span>
            <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                {{ $t('dailywork.currency') }}
            </span>
            <span v-else-if="props.column.field === 'recordDate'" class="text-nowrap">
                {{ $t('dailywork.date') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'debitNo'">
                <b-badge variant="primary" style="cursor: pointer;" @click="getInvoiceInfo(props.row.lawInvoiceId)">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.debitNo }}</span>
                </b-badge>
            </span>
            <span v-else-if="props.column.field === 'agent'">
                <span>{{props.row.agent}}</span>
            </span>
            <span v-else-if="props.column.field === 'invoiceClientAccounting'">
                <span>{{ props.row.invoiceClientAccounting }} </span>
            </span>
            <span v-else-if="props.column.field === 'lawBillingStatus'" style="cursor:pointer" :title="props.row.billingStatus">
                <feather-icon v-if="props.row.lawBillingStatusId == 1" color="green" icon="CircleIcon" />
                <feather-icon v-if="props.row.lawBillingStatusId == 2" color="red" icon="CircleIcon" />
                <feather-icon v-if="props.row.lawBillingStatusId == 3" color="orange" icon="CircleIcon" />
            </span>

            <span v-else-if="props.column.field === 'paymentType'">
                <span>{{ props.row.paymentType }} </span>
            </span>
            <span v-else-if="props.column.field === 'currency'">
                <span>{{ props.row.currency }} </span>
            </span>
            <span v-else-if="props.column.field === 'recordDate'">
                <span>{{ $formatFn(props.row.recordDate) }} </span>
            </span>
            <span v-else-if="props.column.field === 'lawService'">
                <span>{{ props.row.lawService }} </span>
            </span>
            <span v-else-if="props.column.field === 'count'">
                <span>{{ props.row.count }} </span>
            </span>
            <span v-else-if="props.column.field === 'unitPrice'">
                <span>{{ $formatMoney(props.row.unitPrice) }} </span>
            </span>
            <span v-else-if="props.column.field === 'price'">
                <span>{{ $formatMoney(props.row.price) }} </span>
            </span>
            <span v-else-if="props.column.field === 'employee'">
                <span>{{ props.row.employee }} </span>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id,props.row)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        },
        recordNo: {
            type: String
        },
        shortName: {
            type: String
        }
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            checkedStatus: true,
            columns: [{
                    label: this.$t('dailywork.date'),
                    field: 'recordDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('dailywork.service'),
                    field: `lawService`,
                },
                {
                    label: this.$t('dailywork.client_accounting'),
                    field: `invoiceClientAccounting`,
                },
                {
                    label: this.$t('dailywork.count'),
                    field: `count`,
                },
                {
                    label: this.$t('dailywork.currency'),
                    field: `currency`,
                },
                {
                    label: this.$t('dailywork.unit_price'),
                    field: `unitPrice`,
                },
                {
                    label: this.$t('dailywork.amount'),
                    field: `price`,
                },
                {
                    label: this.$t('dailywork.employee'),
                    field: `employee`,
                },
                {
                    label: this.$t('dailywork.type'),
                    field: `lawBillingStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            notInvoiced: true,
            selectedRows: [],
            selectedRowsArray: []
        }
    },
    mounted() {
        this.getDailyworkList()
    },
    methods: {
        handleSelectedRows(selectedRows) {
            this.selectedRows = selectedRows;
        },
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawDailywork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDailyworkList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDailyworkList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.dailyworkFormModal.showInfo(id, this.lawMatterId, this.recordNo,this.shortName)
        },
        getDailyworkList() {
            this.rows = []
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                recordId: this.lawMatterId,
                isNotInvoice: this.notInvoiced
            }
            const controller = {
                name: 'LawDailywork'
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.lawDailyworkList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        changeNotInvoice() {

            if (this.notInvoiced) {
                this.checkedStatus = true
                this.columns = [{
                        label: this.$t('dailywork.isSelected'),
                        field: `isSelected`,
                    },
                    {
                        label: this.$t('dailywork.date'),
                        field: 'recordDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('dailywork.service'),
                        field: `lawService`,
                    },
                    {
                        label: this.$t('dailywork.client_accounting'),
                        field: `invoiceClientAccounting`,
                    },
                    {
                        label: this.$t('dailywork.count'),
                        field: `count`,
                    },
                    {
                        label: this.$t('dailywork.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('dailywork.unit_price'),
                        field: `unitPrice`,
                    },
                    {
                        label: this.$t('dailywork.amount'),
                        field: `price`,
                    },
                    {
                        label: this.$t('dailywork.employee'),
                        field: `employee`,
                    },
                    {
                        label: this.$t('dailywork.type'),
                        field: `lawBillingStatus`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ]
            } else {
                this.checkedStatus = false
                this.columns = [{
                        label: this.$t('dailywork.debitNo'),
                        field: `debitNo`,
                    },
                    {
                        label: this.$t('dailywork.date'),
                        field: 'recordDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('dailywork.service'),
                        field: `lawService`,
                    },
                    {
                        label: this.$t('dailywork.client_accounting'),
                        field: `invoiceClientAccounting`,
                    },
                    {
                        label: this.$t('dailywork.count'),
                        field: `count`,
                    },
                    {
                        label: this.$t('dailywork.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('dailywork.unit_price'),
                        field: `unitPrice`,
                    },
                    {
                        label: this.$t('dailywork.amount'),
                        field: `price`,
                    },
                    {
                        label: this.$t('dailywork.employee'),
                        field: `employee`,
                    },
                    {
                        label: this.$t('dailywork.type'),
                        field: `lawBillingStatus`,
                    },
                ]
            }

            this.getDailyworkList()
        },
        changeChecked(id, status) {
            this.rows = this.rows.map((dailywork) => {
                if (dailywork.id === id) {
                    return {
                        ...dailywork,
                        isSelected: status
                    };
                }
                return dailywork;
            });
        },
        showInvoiceForm() {
            this.selectedRowsArray = [];
            this.selectedRows.selectedRows.forEach(element => {
                this.selectedRowsArray.push(element)
            })
            if (this.selectedRowsArray.length == 0) {
                this.$ErrorSaveAlert()
                return
            }
            this.$refs.invoiceForm.showInfoFromDailyWorkList(this.selectedRowsArray)
        },
        async getInvoiceInfo(id) {
            this.$refs.invoiceForm.showInfo(id)
        },
    },
}
</script>
  
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
