<template>
<div>
    <clients-form :getClientsList="getClientsList" ref="clientsFormModal"></clients-form>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getLast3MonthsPowerOfAttorneyReportList()" v-on:keyup.enter="getLast3MonthsPowerOfAttorneyReportList()">{{ $t('others.list') }}</b-button>
            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('others.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="lawClientPowerOfAttorneyDeadlineReportTable" ref="lawClientPowerOfAttorneyDeadlineReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'clientName'" class="text-nowrap">
                {{ $t('lawClientPowerOfAttorneyDeadlineReport.client_name') }}
            </span>
            <span v-else-if="props.column.field === 'powerOfAttorneyDeadlineDate'" class="text-nowrap">
                {{ $t('lawClientPowerOfAttorneyDeadlineReport.deadline_date') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.agent')" v-b-modal.modal-top variant="info" class="btn-icon mb-1 ml-1" size="sm" @click="getLawClientInfo(props.row.lawClientId)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getLast3MonthsPowerOfAttorneyReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            total: 0,
            pageNo: 1,
            columns: [{
                    label: this.$t('lawClientPowerOfAttorneyDeadlineReport.client_name'),
                    field: `clientName`,
                },
                {
                    label: this.$t('lawClientPowerOfAttorneyDeadlineReport.deadline_date'),
                    field: `powerOfAttorneyDeadlineDate`,
                    formatFn: this.$formatFn
                },

                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getLast3MonthsPowerOfAttorneyReportList()
    },
    methods: {
        getLast3MonthsPowerOfAttorneyReportList() {
            this.rows = []
            const controller = {
                name: 'LawReport',
                actionName: 'GetLast3MonthsPowerOfAttorneyReportList'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.lawClientGetLast3MonthsPowerOfAttorneyReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawReport',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("LawClientGetLast3MonthsPowerOfAttorneyReportList", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getLawClientInfo(lawClientId) {
            this.$refs.clientsFormModal.showInfo(lawClientId)
        }
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
