<template>
<div>
    <matter-form ref="matterForm"></matter-form>

    <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8 mb-1">
                <b-col>
                    <label for="law-matter-customs-registration-list-start-date-input"> {{ $t('lawMatterCustomsRegistrationReport.expiration_date_start') }} </label>
                    <b-form-datepicker id="law-matter-customs-registration-list-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawMatterCustomsRegistrationReport.expiration_date_start')" size="sm" v-model="expirationDateStart" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
                <b-col>
                    <label for="law-matter-customs-registration-list-end-date-input"> {{ $t('lawMatterCustomsRegistrationReport.expiration_date_end') }} </label>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawMatterCustomsRegistrationReport.expiration_date_end')" size="sm" v-model="expirationDateEnd" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button  style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportList()" v-on:keyup.enter="getLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportList()">{{ $t('others.list') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
    <!-- table -->
    <vue-good-table id="lawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportTable" ref="lawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'lawMatterNo'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.law_matter_no') }}
            </span>
            <span v-else-if="props.column.field === 'shortName'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.short_name') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkName'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.trademark_name') }}
            </span>
            <span v-else-if="props.column.field === 'expirationDate'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.expiration_date') }}
            </span>
            <span v-else-if="props.column.field === 'client'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.client_name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('lawMatterCustomsRegistrationReport.agent_name') }}
            </span>
        </template>
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'lawMatterNo'" class="text-nowrap"> 
                    <b-badge class="mr-1" variant="primary" style="cursor: pointer;" @click="showLawMatterForm(props.row.lawMatterId)">
                        <feather-icon icon="bookmarkIcon" />
                        <span>{{ props.row.lawMatterNo }}</span>
                    </b-badge>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getMatterReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            total: 0,
            pageNo: 1,
            expirationDateStart: null,
            expirationDateEnd: null,
            clientName: '',
            columns: [{
                    label: this.$t('lawMatterCustomsRegistrationReport.law_matter_no'),
                    field: `lawMatterNo`,
                },
                {
                    label: this.$t('lawMatterCustomsRegistrationReport.client_name'),
                    field: `client`,
                },
                {
                    label: this.$t('lawMatterCustomsRegistrationReport.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('lawMatterCustomsRegistrationReport.short_name'),
                    field: `shortName`,
                },
                {
                    label: this.$t('lawMatterCustomsRegistrationReport.trademark_name'),
                    field: `trademarkName`,
                },
                {
                    label: this.$t('lawMatterCustomsRegistrationReport.expiration_date'),
                    field: `expirationDate`,
                    formatFn: this.$formatFn
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.expirationDateStart = new Date(new Date().setMonth(new Date().getMonth()-1));
        this.expirationDateEnd = new Date();
        this.getLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportList()
    },
    methods: {
        showLawMatterForm(lawMatterId){
            this.$refs.matterForm.showInfo(lawMatterId)
        },
        getLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportList() {
            this.rows = []
            const controller = {
                name: 'LawReport',
                actionName: 'GetLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReport'
            }
            const data = {
                pageNo: this.pageNo,
                pageLength: this.pageLength,
                expirationDateStart : this.expirationDateStart,
                expirationDateEnd : this.expirationDateEnd
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.getLawMatterCustomsRegistrationListOfLast1MonthExpirationDateReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawReport',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Gümrük Kaydı Hatırlatma Raporu", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
