<template>
<div>
    <clients-form :getClientsList="getClientsList" ref="clientsFormModal"></clients-form>
    <add-new-law-client-form :getClientsList="getClientsList" ref="addNewLawClientFormModal"></add-new-law-client-form>
    <clients-reports-form ref="clientsReportsFormModal"></clients-reports-form>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <div class="mr-1">
                <label for="client-name-input">{{ $t('others.search_name') }}</label>
                <b-form-input size="sm" v-model="name" v-on:keyup.enter="getClientsList()" :placeholder="$t('clients.name')" type="text" class="d-inline-block mb-2" />
            </div>

            <div class="mr-1">
                <label for="client-country-input">{{ $t('clients.country') }}</label>
                <b-form-select id="client-country-input" v-model="countrySelect" size="sm" value-field="id" text-field="name" :options="countryOption" class="mb-2" />
            </div>

            <b-form-checkbox class="custom-control-success mr-3" id="client-is-agent-input" v-model="isAgent"> {{ $t('clients.is_agent') }}</b-form-checkbox>
            <b-form-checkbox class="custom-control-success" id="client-is-owner-input" v-model="isOwner"> {{ $t('clients.is_owner') }}</b-form-checkbox>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getClientsList()" v-on:keyup.enter="getClientsList()">{{ $t('clients.list') }}</b-button>
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="addNewLawClient(0)">{{ $t('clients.add') }}</b-button>
            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('lawyerDailyworkReport.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="clientTable" ref="clientTable" :columns="columns" :rows="rows" :sort-options="{enabled: false}" :search-options="{ enabled: true, externalQuery: searchTerm}" 
    styleClass="vgt-table condensed tableSize" :select-options="{enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class', selectionText: 'rows selected', 
    clearSelectionText: 'clear', disableSelectInfo: true, selectAllByGroup: true}" :pagination-options="{ enabled: true, perPage: pageLength}" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Labels -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('clients.name') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccountingList'">
                <b-row>
                    <b-col>
                        <span v-b-popover.hover.top="$t('clients.case')" style="cursor: pointer;">
                            DV
                        </span>
                    </b-col>
                    <b-col>
                        <span v-b-popover.hover.top="$t('clients.consultancy')" style="cursor: pointer;">
                            LE
                        </span>
                    </b-col>
                    <b-col>
                        <span v-b-popover.hover.top="$t('clients.duty_registration')" style="cursor: pointer;">
                            CU
                        </span>
                    </b-col>
                    <b-col>
                        <span v-b-popover.hover.top="$t('clients.stop_duty')" style="cursor: pointer;">
                            SC
                        </span>
                    </b-col>
                    <b-col>
                        <span v-b-popover.hover.top="$t('clients.detection_of_evidence')" style="cursor: pointer;">
                            DT
                        </span>
                    </b-col>
                    <b-col v-b-popover.hover.top="$t('clients.others')" style="cursor: pointer;">
                        <span>
                            OT
                        </span>
                    </b-col>
                    <b-col v-b-popover.hover.top="$t('clients.smuggling')" style="cursor: pointer;">
                        <span>
                            AS
                        </span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Elements -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Client Name -->
            <span v-if="props.column.field === 'client'">
                <b-col>
                    <b-row>
                        <tr v-if="props.row.isAgent === true && props.row.isOwner === false">
                            <span style="font-size: smaller"><b>{{ props.row.client }}</b>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isOwner === true && props.row.isAgent === false">
                            <span style="font-size: smaller"><b>{{ props.row.client }}</b>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isAgent === true && props.row.isOwner === true">
                            <span style="font-size: smaller"><b>{{ props.row.client }}</b>
                            </span>
                        </tr>
                    </b-row>
                    <b-row v-if="props.row.clientAddress !== null">
                        <span>
                            <tr v-if="clientAddress.address !== null" style="font-size: smaller">{{ props.row.clientAddress.address}}
                            </tr>
                            <tr v-if="clientAddress.country !== null" style="font-size: smaller">{{ props.row.clientAddress.country}}
                            </tr>
                        </span>
                    </b-row>
                </b-col>
            </span>
            <!-- Column: Accounting -->
            <span style="text-align: right" v-else-if="props.column.field === 'accounting'">
                <b-col v-if="props.row.isOwner === true">
                    <b-badge style="color:black;" pill variant="success">{{$t('clients.owner')}}</b-badge>
                </b-col>
                <b-col >
                    <b-row v-for="clientAccounting in props.row.clientAccountingList" v-bind:key="clientAccounting.code">

                        <label> {{clientAccounting.number }} <b-badge  style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge></label>
                    

                        <!-- 
                        <b-col class="col-md-4">
                            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap">{{clientAccounting.note.substr(0, 50)}}</span>
                        </b-col>
                         -->
                    </b-row>
                </b-col>
            </span>
            <!-- Column: AccountingList -->
            <span v-else-if="props.column.field === 'clientAccountingList'">
                <b-row v-if="props.row.isOwner === true">
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfDV > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 1, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfDV }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfLE > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 2, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfLE }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfCU > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 3, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfCU }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfSC > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 4, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfSC }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfDT > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 5, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfDT }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfOT > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 6, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfOT }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.lawClientMatterCountInformation.countOfAS > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(2, 7, props.row.lawClientMatterCountInformation.lawClientId)">{{ props.row.lawClientMatterCountInformation.countOfAS }}</span>
                    </b-col>
                </b-row>
                <b-row v-for="clientAccounting in props.row.clientAccountingList" v-bind:key="clientAccounting.code">
                    <b-col class="col-12">
                        <b-row>
                            <b-col>
                                <span :style="[ clientAccounting.countOfDV > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 1, clientAccounting.id)">{{ clientAccounting.countOfDV }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfLE > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 2, clientAccounting.id)">{{ clientAccounting.countOfLE }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfCU > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 3, clientAccounting.id)">{{ clientAccounting.countOfCU }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfSC > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 4, clientAccounting.id)">{{ clientAccounting.countOfSC }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfDT > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 5, clientAccounting.id)">{{ clientAccounting.countOfDT }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfOT > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 6, clientAccounting.id)">{{ clientAccounting.countOfOT}}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.countOfAS > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 7, clientAccounting.id)">{{ clientAccounting.countOfAS }}</span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getClientsList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            isAgent: false,
            isOwner: false,
            countrySelect: 0,
            countryOption: [],
            total: 0,
            pageLength: 10,
            pageNo: 1,
            dir: false,
            clientAddress: '',
            columns: [
                {
                    label: this.$t('clients.name'),
                    field: `client`,
                },
                {
                    label: this.$t('clients.accounting'),
                    field: `accounting`,
                },
                {
                    field: `clientAccountingList`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            agentOwnerSelect: 0
        }
    },
    mounted() {
        this.getClientsList()
    },
    methods: {
        showDetailList(clientType, lawMatterTypeId, id) {

            if(id != 0){
                this.$refs.clientsReportsFormModal.showInfo(clientType, lawMatterTypeId, id)
            } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getClientsList()
            }
        },
        async deleted(data) {
            data = { id: data.id}
            const controller = { name: 'LawClient'}
            const payload = { data: data, controller: controller}
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getClientsList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getClientsList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.clientsFormModal.showInfo(id)
        },
        addNewLawClient() {
            this.$refs.addNewLawClientFormModal.showInfo()
        },
        getClientsList() {
            if (this.isAgent == false && this.isOwner == false) {
                this.isAgent = null
                this.isOwner = null
            }
            this.rows = []
            const controller = { name: 'LawClient'}
            const data = { clientName: this.name,  pageLength: this.pageLength, pageNo: this.pageNo, isAgent: this.isAgent, isOwner: this.isOwner, countryId: this.countrySelect}
            const payload = { data: data, controller: controller}
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.countrySelect = this.countrySelect
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())

                    response.data.lawClientList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = { name: 'LawClient', actionName: 'CanExport'}
            const data = { columnList: this.columns, DataList: this.rows}
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Clients", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    font-size: 1rem;
    position: static;
    inline-size: max-content !important;
}
</style>
