<template>
<div>
    <invoice-form :getInvoiceList="getInvoiceList" ref="invoiceFormModal"></invoice-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2 mb-1">
                <label for="invoice-status-input">{{ $t('invoiceApproval.invoice_status') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-select id="invoice-status-input" @change="getInvoiceList()" :placeholder="$t('invoiceApproval.invoice_status')" size="sm" v-model="invoiceStatusSelect" value-field="id" text-field="name" :options="invoiceStatusOption" />
                </div>
            </b-col>

            <b-col class="col-md-2 mb-1">
                <label for="invoice-proforma-date-input">{{ $t('invoiceApproval.proforma_date') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-datepicker id="invoice-proforma-date-input" @input="getInvoiceList()" :placeholder="$t('invoiceApproval.proforma_date')" size="sm" v-model="proformaDate" class="mb1" defaultDate: null reset-button today-button :label-today-button="$t('dateTime.today')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </div>
            </b-col>

            <b-col class="col-md-2 mb-1">
                <label for="invoice-debit-no-input">{{ $t('invoiceApproval.debit_no') }}</label>
                <b-form-input size="sm" v-model="debitNo" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoiceApproval.debit_no')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-md-2 mb-1">
                <label for="invoice-agent-name-input">{{ $t('invoiceApproval.invoice_client') }}</label>
                <b-form-input size="sm" v-model="invoiceClient" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoiceApproval.invoice_client')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-md-2 mb-1">
                <b-form>
                    <label for="invoice-employee-input">{{ $t('invoiceApproval.employee') }}</label>
                    <b-form-select id="invoice-employee-input" :placeholder="$t('invoiceApproval.employee')" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" v-on:keyup.enter="getInvoiceList()" @click="getInvoiceList()">{{ $t('invoiceApproval.list') }}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('others.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="invoiceApprovalTable" ref="invoiceApprovalTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'debitNo'" class="text-nowrap">
                {{ $t('invoiceApproval.debit_no') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('invoiceApproval.amount') }}
            </span>
            <span v-else-if="props.column.field === 'recordDate'" class="text-nowrap">
                {{ $t('invoiceApproval.proforma_date') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('invoiceApproval.employee') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'invoiceClient'">
                <span>
                    {{ props.row.invoiceClient }}
                </span>
                <br>
                <span style="font-size: smaller;">
                    {{ props.row.invoiceClientAccounting }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'recordDate'">
                <span>
                    {{$formatFn(props.row.recordDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'total'">
                <span>
                    {{$formatMoney(props.row.total) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button v-b-tooltip.html :title="$t('others.show_invoice')" v-b-modal.modal-top variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="downloadInvoicePdf(props.row.id)">
                        <feather-icon icon="FileTextIcon" />
                    </b-button>

                    <b-button v-if="props.row.lawInvoiceStatusId == 3" v-b-tooltip.html :title="$t('others.ToReturn')" v-b-modal.modal-top variant="danger" class="btn-icon mb-1 ml-1" size="sm" @click="changeStatus(props.row.id)">
                        <feather-icon icon="UnlockIcon" />
                    </b-button>
                    <!-- 
                    <b-button v-if="props.row.lawInvoiceStatusId == 3" v-b-tooltip.html :title="$t('others.Transfer')" v-b-modal.modal-top variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="approvalInvoice(props.row.id)">
                        <feather-icon icon="CompassIcon" />
                    </b-button>

                    <b-button v-b-tooltip.html :title="$t('others.edit_note')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInvoiceEditNoteInfo(props.row.id)">
                        <feather-icon icon="Edit3Icon" />
                    </b-button> -->
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getInvoiceList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('invoice.debitNo'),
                    field: `debitNo`,
                },
                {
                    label: this.$t('invoiceApproval.invoice_client'),
                    field: `invoiceClient`,
                },
                {
                    label: this.$t('invoiceApproval.law_kdv_type'),
                    field: `lawKdvType`,
                },
                {
                    label: this.$t('invoiceApproval.amount'),
                    field: `total`,
                },
                {
                    label: this.$t('invoiceApproval.currency_code'),
                    field: `currency`,
                },
                {
                    label: this.$t('invoiceApproval.proforma_date'),
                    field: `recordDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('invoiceApproval.employee'),
                    field: `employee`,
                },
                {
                    label: this.$t('invoiceApproval.matter_no'),
                    field: `lawMatterRecordNo`,
                },
                {
                    label: this.$t('invoiceApproval.invoice_status'),
                    field: `lawInvoiceStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '12em'
                },
            ],
            rows: [],
            searchTerm: '',
            debitNo: '',
            invoiceClient: '',
            employeeSelect: 0,
            employeeOption: [],
            invoiceStatusSelect: 0,
            invoiceStatusOption: [],
            proformaDate: null,
            lawKdvTypeSelect: 0,
            lawKdvTypeOption: [],
            currencySelect: 0,
            currencyOption: [],
            lawMatterNo: '',
            proformaDateStart: null,
            proformaDateEnd: null
        }
    },
    mounted() {
        this.invoiceStatusSelect = 3
        this.getInvoiceList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.invoiceFormModal.showInfo(id)
        },
        async getInvoiceEditNoteInfo(id) {
            this.$refs.invoiceEditNoteFormModal.showInfo(id)
        },
        getInvoiceList() {
            this.rows = []
            const controller = {
                name: 'LawInvoice',
                actionName: 'GetLawInvoiceApprovalList'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                debitNo: this.debitNo,
                invoiceClient: this.invoiceClient,
                employeeId: this.employeeSelect,
                invoiceClientAccountingNo: this.invoiceClientAccountingNo,
                lawInvoiceStatusId: this.invoiceStatusSelect,
                //Dont ask me ? there should be bigger search menu .
                proformaDateStart: this.proformaDate,
                proformaDateEnd: this.proformaDate,
                //
                lawInvoiceTypeId: this.lawInvoiceTypeId,
                lawKdvTypeId: this.lawKdvTypeSelect,
                currencyId: this.currencySelect,
                lawMatterNo: this.lawMatterNo
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.lawInvoiceList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.currencyOption = response.data.currencyList
                    this.lawKdvTypeOption = response.data.lawKdvTypeList
                    this.invoiceStatusOption = response.data.lawInvoiceStatusList
                    this.currencyOption = response.data.currencyList
                    this.employeeOption = response.data.employeeList

                    this.currencyOption.splice(0, 0, this.$nullSelected("code")),
                        this.invoiceStatusOption.splice(0, 0, this.$nullSelected()),
                        this.lawKdvTypeOption.splice(0, 0, this.$nullSelected()),
                        this.employeeOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        //This Code here and on  backend side nosense..
        async downloadInvoicePdf(id) {
            var data = {
                id: id
            }
            var controller = {
                name: 'LawInvoice'
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    data = {
                        lawInvoice: response.data.lawInvoice,
                        lawInvoiceDetailList: response.data.lawInvoiceDetailList
                    }
                    controller = {
                        name: 'LawInvoice',
                        actionName: 'DownloadProforma'
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })

                }
            })
        },
        approvalInvoice(id) {
            this.$PrivateConfirm(this.$t("invoiceApproval.confirmTitle"), this.$t("invoiceApproval.confirmMessage"), "warning", this.$t("invoiceApproval.confirmButtonText"), this.$t("invoiceApproval.cancelButtonText")).then(result => {
                if (result.value) {
                    var data = {
                        id: id,
                        lawInvoiceStatusId: 4
                    }
                    var controller = {
                        name: 'Invoice',
                        actionName: 'ChangeInvoiceStatus'
                    }
                    var payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            data = {
                                invoiceId: id
                            }
                            controller = {
                                name: 'InvoiceStatement',
                                actionName: 'ControlAndSaveStatement'
                            }
                            payload = {
                                data: data,
                                controller: controller
                            }

                            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.resultStatus == true) {

                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                            })

                            this.getInvoiceList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        changeStatus(id) {
            this.$PrivateConfirm(this.$t("invoiceApproval.confirmTitleReturnInvoice"), this.$t("invoiceApproval.confirmMessageReturnInvoice"), "warning", this.$t("invoiceApproval.confirmButtonTextReturnInvoice"), this.$t("invoiceApproval.cancelButtonTextReturnInvoice")).then(result => {
                if (result.value) {
                    const data = {
                        lawInvoiceId: id,
                        lawInvoiceStatusId: 1
                    }
                    const controller = {
                        name: 'LawInvoice',
                        actionName: 'ChangeLawInvoiceStatus'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getInvoiceList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'Invoice',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Faturalar", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
