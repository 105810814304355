<template>
    <div>
      <employee-form :getEmployeeList="getEmployeeList" ref="employeeFormModal"></employee-form>  
      <b-row>
        <b-col>
          <div style="float:left;" class="custom-search d-flex justify-content-end">
            <div class="d-flex align-items-center">
              <b-form-input size="sm" v-model="searchTerm" :placeholder=" $t('others.search')" type="text" class="d-inline-block" />
            </div> 
            <div class="d-flex align-items-center ml-2">
            <b-row>
              <b-col>
                  <b-form-radio  v-model="radioActive" name="some-radios" value="2" @change="getEmployeeList()" > {{ $t('others.active') }}</b-form-radio>
              </b-col>
              <b-col>
                  <b-form-radio v-model="radioActive" name="some-radios" value="1" @change="getEmployeeList()" > {{ $t('others.passive') }}</b-form-radio>
              </b-col>
              <b-col>
                  <b-form-radio v-model="radioActive" name="some-radios2" value="3" @change="getEmployeeList()" > {{ $t('others.all') }}</b-form-radio>
              </b-col>
            </b-row> 
            </div>
          </div>
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('others.excel') }}</b-button>                                                   </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <vue-good-table ref="employeeTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{ enabled: true, externalQuery: searchTerm}"
      :select-options="{ enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class', selectionText: 'rows selected', clearSelectionText: 'clear',
      disableSelectInfo: true, selectAllByGroup: true}"
      :pagination-options="{ enabled: true, perPage:pageLength }">
    <div slot="emptystate">
      {{ $t('dataTable.data_not_found') }}
    </div>
    <!-- Row: Table Row -->
    <template
      slot="table-column"
      slot-scope="props"
    >
      <span  v-if="props.column.field ==='isDeleted'" class="text-nowrap" style="width: 0.01em;">
        {{ $t('employee.act_pass') }}
      </span>
      <span v-else-if="props.column.field ==='name'" class="text-nowrap" >
        {{ $t('employee.name') }}
      </span>
      <span v-else-if="props.column.field ==='surname'" class="text-nowrap" >
        {{ $t('employee.surname') }}
      </span>
      <span v-else-if="props.column.field ==='email'" class="text-nowrap" >
        {{ $t('employee.email') }}
      </span>
      <span v-else-if="props.column.field ==='department'" class="text-nowrap" >
        {{ $t('employee.department') }}
      </span>
      <span v-else-if="props.column.field ==='title'" class="text-nowrap" >
        {{ $t('employee.title') }}
      </span>
      <span v-else-if="props.column.field ==='role'" class="text-nowrap" >
        {{ $t('employee.role') }}
      </span>
      <span v-else-if="props.column.field ==='startingDate'" class="text-nowrap" >
        {{ $t('employee.starting_date') }}
      </span>
      <span v-else-if="props.column.field ==='endingDate'" class="text-nowrap" >
        {{ $t('employee.ending_date') }}
      </span>
      <span v-else-if="props.column.field ==='process'" class="text-nowrap" >
        {{ $t('others.process') }}
      </span>
    </template>
    <!-- Column: Table Column -->
    <template slot="table-row" slot-scope="props" :class="classRow" >
          <!-- Column: employee Name -->
        <span v-if="props.column.field == 'isDeleted'">
            <span v-if="props.row.isDeleted == false">
                <feather-icon color="green" icon="CircleIcon" />
            </span>
            <span v-else-if="props.row.isDeleted == true">
                <feather-icon color="red" icon="CircleIcon" />
            </span>
        </span>
      <!-- Column: Process -->
      <span v-else-if="props.column.field === 'process'">
        <span>
            <b-button v-b-tooltip.html :title=" $t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                <feather-icon icon="EditIcon" />
            </b-button>          
        </span>
      </span>
    </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})"/>
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                <b-pagination  :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                    class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})" >
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>
                </div>
            </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  <script>
  export default{
    data(){
      return{
        radioActive:"2",
        columns: [
          {
            label: this.$t('employee.act_pass'),
            field: `isDeleted`,
            width: '50px',
          },
          {
            label: this.$t('employee.name') ,
            field: `name`,
          },
          {
            label: this.$t('employee.surname'),
            field: 'surname',
          },
          {
           label: this.$t('employee.email'),
           field: 'email', 
          },
          {
            label : this.$t('employee.department'),
            field : 'department',
          },
          {
            label : this.$t('employee.title') ,
            field : 'title',
          },
          {
            label : this.$t('employee.role'),
            field : 'role',
          },
          {
            label : this.$t('employee.starting_date'),
            field : 'startingDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('employee.ending_date'),
            field : 'endingDate',
            formatFn: this.$formatFn
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        employeeList:[],
        pageLength: 10,
        dir: false,
        searchTerm: '',
        classRow: ''
      }
    },
    mounted(){
      this.getEmployeeList()
    },
    methods:{
      async getInfo(id){
          this.$refs.employeeFormModal.showInfo(id)
      },
      getEmployeeList(){
            const data = {id: this.radioActive}
            this.rows = []
            const controller = {name : 'LawEmployee'}
            const payload = {data : data ,controller: controller}
            this.$store.dispatch('moduleAuth/getList',payload).then((response) => {
              if(response.data.resultStatus == true){
                this.rows = response.data.lawEmployeeList;
              }
              else{
               this.$WarningAlert(response.data.resultMessage)
              }
            }
          )
      },
      exportExcel() {
              const controller = { name: 'LawEmployee', actionName: 'CanExport'}            
              const data = { columnList : this.columns, DataList : this.rows}
              const payload = { data : data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                      this.$downloadFile("Dündar Hukuk Kullanicilar", response.data.base64, 'xlsx')
                  }
                   else {
                      this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
    }
  }
  </script>
  <style lang="scss" >
  .excel-css{
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem; 
  }
  .darken-success{
    background-color: #1f9d57 !important;
  }
  .darken-danger{
    background-color: #e42728 !important;
  }
  </style>