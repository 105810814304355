<template>
    <div>
        <!-- modal -->
        <b-modal id="clientsAccountingFormModal" centered size="lg" no-close-on-backdrop ref="clientsAccountingFormModal" :title="$t('clientsAccounting.add_accounting_info')" hide-footer>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col>
                        <label for="account-id-number-input">{{ $t('clientsAccounting.accounting_id') }}</label>
                        <b-form-input disabled id="account-id-number-input" v-model="accountIdNumber" maxlength="10" readonly />
                    </b-col>
                    <b-col>
                        <label for="clients-accounting-foreign-currency">{{ $t('clientsAccounting.foreign_currency') }}</label>
                        <b-form>
                            <validation-provider :name="$t('clientsAccounting.foreign_currency')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select disabled :state="errors.length > 0 ? false : null" v-model="foreignCurrencySelect" value-field="id" text-field="name" :options="foreignCurrencyOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col>
                        <label for="jurisdiction-input">{{ $t('clientsAccounting.jurisdiction') }}</label>
                        <b-form>
                            <validation-provider :name="$t('clientsAccounting.jurisdiction')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select disabled :state="errors.length > 0 ? false : null" v-model="jurisdictionSelect" value-field="id" text-field="name" :options="jurisdictionOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col>
                        <div>
                            <label for="account_number-input">
                                {{ $t('clientsAccounting.is_agent') }}
                            </label>
                            <b-form-checkbox disabled :checked="agentChecked" class="custom-control-success" name="check-button" v-model="agentChecked" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <label for="account_number-input">
                                {{ $t('clientsAccounting.used_for_invoice') }}
                            </label>
                            <b-form-checkbox disabled :checked="invoiceChecked" class="custom-control-success" name="check-button" v-model="invoiceChecked" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="clients-accounting-price-list">{{ $t('clientsAccounting.price_list') }}</label>
                            <b-form-select disabled v-model="priceListSelect" value-field="id" text-field="name" :options="priceListOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <label for="discount-input">{{ $t('clientsAccounting.discount') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('clientsAccounting.discount')" rules="between:0,100">
                                <b-form-input disabled id="discount-input" type="number" v-model="discount" maxlength="10" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-4">
                        <label for="invoice-alert-day-input">{{ $t('clientsAccounting.invoice_alert_day') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('clientsAccounting.invoice_alert_day')" rules="between:0,9999">
                                <b-form-input disabled id="invoice-alert-day-input" type="number" v-model="invoiceAlertDay" maxlength="10" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="note-to-be-on-invoice-input">{{ $t('clientsAccounting.note_to_be_on_invoice') }}</label>
                            <b-form-select disabled size="sm" v-model="noteToBeOnInvoiceSelect" value-field="id" text-field="code" :options="noteToBeOnInvoiceOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col class="col-lg-12">
                        <b-form>
                            <b-form-group :label="$t('clientsAccounting.special_invoice_accounting')" label-for="special_invoice_accounting">
                                <v-select disabled size="sm" id="specialInvoiceAccounting" v-model="specialInvoiceAccountingSelect" :options="specialInvoiceAccountingOption" @input="setSpecialInvoiceAccountingId" label="clientName" @search="onSpecialSearch">
                                </v-select>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <b-form-group :label="$t('clientsAccounting.different_invoice_accounting')" label-for="different_invoice_accounting">
                                <v-select disabled size="sm" id="specialInvoiceAccounting" v-model="differentInvoiceAccountingSelect" :options="differentInvoiceAccountingOption" @input="setDifferentInvoiceAccountingId" label="clientName" @search="onDifferentSearch">
                                </v-select>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <b-form-group :label="$t('clientsAccounting.accounting_category')" label-for="accountingCategory">
                                <b-form-select disabled size="sm" id="accountingCategory" v-model="accountingCategorySelect" :options="accountingCategoryOption" text-field="name" value-field="id">
                                </b-form-select>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="invoice-discount-format">{{ $t('clientsAccounting.invoice_discount_format') }}</label>
                        <b-form>
                            <validation-provider :name="$t('clientsAccounting.invoice_discount_format')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select disabled :state="errors.length > 0 ? false : null" size="sm" v-model="invoiceDiscountFormatSelect" value-field="id" text-field="name" :options="invoiceDiscountFormatOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <b-form>
                                <label for="financial-ref-input">{{ $t('clientsAccounting.financial_ref') }}</label>
                                <b-form-group>
                                    <b-form-input disabled id="financial-ref-input" size="sm" v-model="financialRef" maxlength="50" />
                                </b-form-group>
                            </b-form>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="textarea-default">{{ $t('clients.notes') }}</label>
                        <b-form-textarea disabled id="textarea-default" :placeholder="$t('clients.notes')" v-model="notes" rows="3" />
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getClientsAccountingList: {
                type: Function
            },
            getClientsList: {
                type: Function
            },
        },
        data() {
            return {
                foreignCurrencySelect: 0,
                foreignCurrencyOption: [],
                accountingInput: '',
                jurisdictionSelect: 0,
                jurisdictionOption: [],
                priceListSelect: 0,
                priceListOption: [],
                discount: 0,
                notes: '',
                accountIdNumber: null,
                agentChecked: false,
                invoiceChecked: false,
                financialRef: '',
                id: 0,
                clientId: 0,
                invoiceAlertDay: 0,
                specialInvoiceAccountingSelect: 0,
                specialInvoiceAccountingOption: [],
                differentInvoiceAccountingSelect: 0,
                differentInvoiceAccountingOption: [],
                noteToBeOnInvoiceOption: [],
                noteToBeOnInvoiceSelect: 0,
                accountingCategoryOption: [],
                accountingCategorySelect: 0,
                invoiceDiscountFormatSelect: 0,
                invoiceDiscountFormatOption: [],
            }
        },
        methods: {
            showInfo(id, clientId) {
                this.$refs['clientsAccountingFormModal'].show()
                this.id = id
                this.clientId = clientId;
                const data = {
                    id: this.id
                }
                const controller = {
                    name: 'ClientAccounting'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.foreignCurrencyOption = response.data.currencyList
                        this.foreignCurrencySelect = response.data.clientAccounting.currencyId
                        this.jurisdictionOption = response.data.jurisdictionList
                        this.jurisdictionSelect = response.data.clientAccounting.jurisdictionId
                        this.agentChecked = response.data.clientAccounting.forAgent
                        this.invoiceChecked = response.data.clientAccounting.forInvoice
                        this.financialRef = response.data.clientAccounting.financialRef
                        this.priceListOption = response.data.priceListList
                        this.accountIdNumber = response.data.clientAccounting.number
                        this.priceListSelect = response.data.clientAccounting.priceListId
                        this.notes = response.data.clientAccounting.note
                        this.discount = response.data.clientAccounting.discount
                        this.noteToBeOnInvoiceOption = response.data.noteToBeOnInvoiceList
                        this.accountingCategorySelect = response.data.clientAccounting.clientAccountingCategoryId
                        this.accountingCategoryOption = response.data.clientAccountingCategoryList
                        this.invoiceDiscountFormatOption = response.data.invoiceDiscountFormatList
                        this.invoiceDiscountFormatOption.splice(0, 0, this.$nullSelected())
                        this.accountingCategoryOption.splice(0, 0, this.$nullSelected())
                        this.foreignCurrencyOption.splice(0, 0, this.$nullSelected())
                        this.jurisdictionOption.splice(0, 0, this.$nullSelected())
                        this.priceListOption.splice(0, 0, this.$nullSelected())
                        this.noteToBeOnInvoiceOption.splice(0, 0, this.$nullSelected('code'))
                        this.invoiceAlertDay = response.data.clientAccounting.invoiceAlertDay
                        if (response.data.clientAccounting.noteToBeOnInvoiceId > 0) {
                            this.noteToBeOnInvoiceSelect = response.data.clientAccounting.noteToBeOnInvoiceId
                        }
                        if (response.data.clientAccounting.invoiceDiscountFormatId > 0) {
                            this.invoiceDiscountFormatSelect = response.data.clientAccounting.invoiceDiscountFormatId
                        } 
                        else {
                            this.invoiceDiscountFormatSelect = 1
                        }
                        if (response.data.clientAccounting.specialInvoiceAccountingId > 0) {
                            const clientAccountingData = {
                                clientAccountingId: response.data.clientAccounting.specialInvoiceAccountingId
                            }
                            const clientAccountingController = {
                                name: 'ClientAccounting',
                                actionName: 'GetClientAccountingByClientName'
                            }
                            const clientAccountingPayload = {
                                data: clientAccountingData,
                                controller: clientAccountingController
                            }
                            this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                                this.specialInvoiceAccountingOption = clientAccountingResponse.data.clientList
                                this.specialInvoiceAccountingSelect = clientAccountingResponse.data.clientList[0]
                            })
                        } else {
                            this.specialInvoiceAccountingOption = []
                            this.specialInvoiceAccountingSelect = 0;
                        }
                        if (response.data.clientAccounting.differentInvoiceAccountingId > 0) {
                            const clientAccountingData = {
                                clientAccountingId: response.data.clientAccounting.differentInvoiceAccountingId
                            }
                            const clientAccountingController = {
                                name: 'ClientAccounting',
                                actionName: 'GetClientAccountingByClientName'
                            }
                            const clientAccountingPayload = {
                                data: clientAccountingData,
                                controller: clientAccountingController
                            }
                            this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                                this.differentInvoiceAccountingOption = clientAccountingResponse.data.clientList
                                this.differentInvoiceAccountingSelect = clientAccountingResponse.data.clientList[0]
                            })
                        } else {
                            this.differentInvoiceAccountingOption = []
                            this.differentInvoiceAccountingSelect = 0;
                        }
    
                    } else {
                        this.$bvModal.hide('clientAccountingFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            setSpecialInvoiceAccountingId(event) {
                this.specialInvoiceAccountingId = event.clientAccountingId
            },
            setDifferentInvoiceAccountingId(event) {
                this.differentInvoiceAccountingId = event.clientAccountingId
            },
            onSpecialSearch(search, loading) {
                if (search.length) {
                    loading(true);
                    this.searchSpecialInvoiceAccounting(loading, search, this);
                }
            },
            onDifferentSearch(search, loading) {
                if (search.length) {
                    loading(true);
                    this.searchDifferentInvoiceAccounting(loading, search, this);
                }
            },
            searchSpecialInvoiceAccounting(loading, search, vm) {
                if (search.length > 2) {
                    const data = {
                        clientAccountingId: 0,
                        clientName: search
                    }
                    const controller = {
                        name: 'ClientAccounting',
                        actionName: 'GetClientAccountingByClientName'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        this.specialInvoiceAccountingOption = response.data.clientList
                        loading(false)
                    })
                }
            },
            searchDifferentInvoiceAccounting(loading, search, vm) {
                if (search.length > 2) {
                    const data = {
                        clientAccountingId: 0,
                        clientName: search
                    }
                    const controller = {
                        name: 'ClientAccounting',
                        actionName: 'GetClientAccountingByClientName'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        this.differentInvoiceAccountingOption = response.data.clientList
                        loading(false)
                    })
                }
            },
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    [dir] .vs__selected-options {
        padding: 0 2px;
        font-size: 0.9em;
    }
    </style>
    