<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8">
                <b-col>
                    <label for="credit-notes-credit-note-input"> {{ $t('creditNotes.credit_note') }} </label>
                    <b-form-input id="credit-notes-credit-note-input" size="sm" v-model="creditNote" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.credit_note')" type="number" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="credit-notes-account-number-input"> {{ $t('creditNotes.account_number') }} </label>
                    <b-form-input id="credit-notes-account-number-input" size="sm" v-model="accountNumber" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.account_number')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="credit-notes-matter-no-input"> {{ $t('creditNotes.matter_no') }} </label>
                    <b-form-input id="credit-notes-matter-no-input" size="sm" v-model="matterNo" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.matter_no')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="credit-notes-agent-name-input"> {{ $t('creditNotes.agent_name') }} </label>
                    <b-form-input id="credit-notes-agent-name-input" size="sm" v-model="agentName" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.agent_name')" type="text" class="d-inline-block mr-1" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getCreditNotesList()" v-on:keyup.enter="getCreditNotesList()">{{ $t('creditNotes.list') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-lg-3">
                                <label for="credit-notes-start-total-input"> {{ $t('creditNotes.start_total') }} </label>
                                <b-form-input id="credit-notes-start-total-input" size="sm" v-model="startTotal" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.start_total')" type="number" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="credit-notes-end-total-input"> {{ $t('creditNotes.end_total') }} </label>
                                <b-form-input id="credit-notes-end-total-input" size="sm" v-model="endTotal" v-on:keyup.enter="getCreditNotesList()" :placeholder="$t('creditNotes.end_total')" type="number" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="credit-notes-start-date-input"> {{ $t('creditNotes.start_date') }} </label>
                                <b-form-datepicker id="credit-notes-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('creditNotes.start_date')" size="sm" v-model="startDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="credit-notes-end-date-input"> {{ $t('creditNotes.end_date') }} </label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('creditNotes.end_date')" size="sm" v-model="endDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="creditNotesTable" ref="creditNotesTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'agent'" class="text-nowrap">
                    {{ $t('creditNotes.agent_name') }}
                </span>
                <span v-else-if="props.column.field === 'accountNumber'" class="text-nowrap">
                    {{ $t('creditNotes.account_number') }}
                </span>
                <span v-else-if="props.column.field === 'lawMatterRecordNo'" class="text-nowrap">
                    {{ $t('creditNotes.matter_no') }}
                </span>
                <span v-else-if="props.column.field === 'debitNo'" class="text-nowrap">
                    {{ $t('creditNotes.debitNo') }}
                </span>
                
                <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                    {{ $t('creditNotes.date') }}
                </span>
                <span v-else-if="props.column.field === 'matter'" class="text-nowrap">
                    {{ $t('creditNotes.matter_name') }}
                </span>
                <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                    {{ $t('creditNotes.status') }}
                </span>
                <span v-else-if="props.column.field === 'currencyCode'" class="text-nowrap">
                    {{ $t('creditNotes.currency') }}
                </span>
                <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                    {{ $t('creditNotes.total') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>

            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.proforma')" v-b-modal.modal-top variant="secondary"
                          class="btn-icon mb-1 ml-1" size="sm" @click="getProformaInfo(props.row.invoiceId)">
                          <feather-icon icon="FilePlusIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title="$t('others.ledes')" variant="light" class="btn-icon mb-1 ml-1"
                          size="sm" @click="getLedesInfo(props.row.id)">
                          <feather-icon icon="AlignLeftIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getCreditNotesList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                creditNote: 0,
                accountNumber: '',
                matterNo: '',
                agentName: '',
                startTotal: 0.0,
                endTotal: 0.0,
                startDate: null,
                endDate: null,
                columns: [{
                        label: this.$t('creditNotes.agent_name'),
                        field: `agent`,
                    },
                    {
                        label: this.$t('creditNotes.account_number'),
                        field: `accountNumber`,
                    },
                    {
                        label: this.$t('creditNotes.matter_no'),
                        field: `lawMatterRecordNo`,
                    },                   
                    {
                        label: this.$t('creditNotes.debitNo'),
                        field: `debitNo`,
                    },
                    {
                        label: this.$t('creditNotes.date'),
                        field: `date`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('creditNotes.matter_name'),
                        field: `matter`,
                    },
                    {
                        label: this.$t('creditNotes.status'),
                        field: `status`,
                    },
                    {
                        label: this.$t('creditNotes.currency'),
                        field: `currencyCode`,
                    },
                    {
                        label: this.$t('creditNotes.total'),
                        field: `total`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.getCreditNotesList()
        },
        methods: {
            async getProformaInfo(invoiceId) {
                const data = {
                    id: invoiceId
                }
                const controller = {
                    name: 'LawInvoice',
                    actionName: 'DownloadCreditNoteProforma'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            async getLedesInfo(id) {
                //ToDo Buraya istenen modal yazılacak.
            },            
            getCreditNotesList() {
                this.rows = []
                const controller = { name: 'LawReport', actionName: 'GetLawCreditNoteReportList' }
                const data = { pageLength: this.pageLength, pageNo: this.pageNo, debitNo: this.creditNote, accountingNo: this.accountNumber, matterNo: this.matterNo, 
                agentName: this.agentName, startTotal: this.startTotal, endTotal: this.endTotal, startDate: this.startDate, endDate: this.endDate }
                const payload = { controller: controller, data: data }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.lawCreditNoteList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("CreditNotes", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    