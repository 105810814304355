<template>
    <div>
      <!-- <FullCalendar :options='calendarOptions'>
        <template v-slot:eventContent='arg'>
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar> -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar"/>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import trLocale from "@fullcalendar/core/locales/tr";
  
  export default {
    data() {
      return {
        calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
          initialView: 'dayGridMonth',
          weekends: false,
          dateClick: this.handleDateClick,
          locale: trLocale,
          events: [
            { title: 'Deneme', start: new Date() }
          ]
        }
      }
    },
    methods: {
        handleDateClick: function(arg) {
            alert('date click! ' + arg.dateStr)
        },
    }
  }
  </script>