<template>
<div>
    <matter-form :getMatterList="getMatterList" ref="matterFormModal"></matter-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-lg-2">
                <label for="matter-matter-type-input">{{ $t('matter.matter_type') }}</label>
                <b-form-select id="matter-matter-type-input" @change="getMatterList()" :placeholder="$t('matter.matter_type')" size="sm" v-model="matterTypeSelect" value-field="id" text-field="name" :options="matterTypeOption" />
            </b-col>
            <b-col class="col-lg-2">
                <label for="matter-matterNo-input">{{ $t('matter.record_no') }}</label>
                <b-form-input size="sm" v-model="matterNo" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.record_no')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-lg-2">
                <label for="matter-client-input">{{ $t('matter.client') }}</label>
                <b-form-input size="sm" v-model="client" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.client')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-lg-2">
                <label for="matter-agent-input">{{ $t('matter.agent') }}</label>
                <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.agent')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-lg-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" v-on:keyup.enter="getMatterList()" @click="getMatterList()">{{ $t('matter.list') }}</b-button>
                    <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('matter.add') }}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('lawyerDailyworkReport.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-lg-3">
                                <label for="law-matter-operation-record-no-input">{{ $t('matter.operation_record_no') }}</label>
                                <b-form-input size="sm" v-model="operationRecordNo" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.operation_record_no')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="law-matter-agent-reference-no-input">{{ $t('matter.agent_ref_no') }}</label>
                                <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.agent_ref_no')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="matter-related-input">{{ $t('matter.related') }}</label>
                                <b-form-select id="matter-related-input" :placeholder="$t('matter.related')" size="sm" v-model="relatedSelect" value-field="id" text-field="name" :options="relatedOption" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="law-matter-status-input">{{ $t('matter.matter_status') }}</label>
                                <b-form-select id="law-matter-status-input" @change="getMatterList()" :placeholder="$t('matter.matter_status')" size="sm" v-model="lawMatterStatusSelect" value-field="id" text-field="name" :options="lawMatterStatusOption" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-lg-3">
                                <label for="law-matter-first-lawyer-input">{{ $t('matter.first_lawyer') }}</label>
                                <b-form-select id="law-matter-first-lawyer-input" @change="getMatterList()" :placeholder="$t('matter.first_lawyer')" size="sm" v-model="firstLawyerSelect" value-field="id" text-field="name" :options="firstLawyerOption" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="law-matter-second-lawyer-input">{{ $t('matter.second_lawyer') }}</label>
                                <b-form-select id="law-matter-second-lawyer-input" @change="getMatterList()" :placeholder="$t('matter.second_lawyer')" size="sm" v-model="secondLawyerSelect" value-field="id" text-field="name" :options="secondLawyerOption" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="law-matter-record-date-start-input">{{ $t('matter.record_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matter.record_date_start')" size="sm" v-model="recordDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="law-matter-record-date-end-input">{{ $t('matter.record_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matter.record_date_end')" size="sm" v-model="recordDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for="caseNumber-input">{{ $t('matterInfo.case_number') }}</label>
                                <b-form-input size="sm" v-model="caseNumber" v-on:keyup.enter="getMatterList()" :placeholder="$t('matterInfo.case_number')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col>
                                <label for="decisionNo-input">{{ $t('matterSupremeCourt.decision_no') }}</label>
                                <b-form-input size="sm" v-model="decisionNo" v-on:keyup.enter="getMatterList()" :placeholder="$t('matterSupremeCourt.decision_no')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col>
                                <label for="customsOffice-input">{{ $t('matterStopCustoms.office') }}</label>
                                <b-form-input size="sm" v-model="customsOffice" v-on:keyup.enter="getMatterList()" :placeholder="$t('matterStopCustoms.office')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col>
                                <label for="lawCourt-input">{{ $t('matterInfo.court') }}</label>
                                <b-form-select id="lawCourt-input" @change="getMatterList()" :placeholder="$t('matterInfo.court')" size="sm" v-model="lawCourtSelect" value-field="id" text-field="name" :options="lawCourtOption" />
                            </b-col>                            
                            <b-col>
                                <label for="plaintiffOrDefendantOrOppositeSide-input">{{ $t('matter.plaintiffOrDefendantOrOppositeSide') }}</label>
                                <b-form-input size="sm" v-model="plaintiffOrDefendantOrOppositeSide" v-on:keyup.enter="getMatterList()" :placeholder="$t('matter.plaintiffOrDefendantOrOppositeSide')" type="text" class="d-inline-block" />
                            </b-col>                            
                        </b-row>
                        <b-row v-if="matterTypeSelect == 1">
                            <b-col v-if="matterTypeSelect == 1" class="col-lg-3"> 
                                <label for="matter-matter-sub-type-input">{{ $t('matter.matter_sub_type') }}</label>
                                <b-form-select id="matter-sub-type-input" @change="getMatterList()" :placeholder="$t('matter.matter_sub_type')" size="sm" v-model="lawMatterSubTypeSelect" value-field="id" text-field="name" :options="lawMatterSubTypeOption" />
                            </b-col>
                            <b-col v-if="matterTypeSelect == 1" class="col-lg-3">
                                <label for="matter-matter-case-type-input">{{ $t('matter.matter_case_type') }}</label>
                                <b-form-select id="matter-case-type-input" @change="getMatterList()" :placeholder="$t('matter.matter_case_type')" size="sm" v-model="lawMatterCaseTypeSelect" value-field="id" text-field="name" :options="lawMatterCaseTypeOption" />
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>

        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="matterTable" ref="matterTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('matter.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'client'" class="text-nowrap">
                {{ $t('matter.client') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('matter.agent') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('matter.name') }}
            </span>
            <span v-else-if="props.column.field === 'operationRecordNo'" class="text-nowrap">
                {{ $t('matter.stock_ref') }}
            </span>
            <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                {{ $t('matter.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getMatterList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            total: 0,
            pageNo: 1,
            columns: [{
                    label: this.$t('matter.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('matter.client'),
                    field: `client`,
                },
                {
                    label: this.$t('matter.agent'),
                    field: `agent`,
                },
                {
                    label: this.$t('matter.matter_type'),
                    field: `lawMatterType`,
                },
                {
                    label: this.$t('matter.name'),
                    field: `name`,
                },
                {
                    label: this.$t('matter.stock_ref'),
                    field: `operationRecordNo`,
                },
                {
                    label: this.$t('matter.status'),
                    field: `lawMatterStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            matterNo: '',
            client: '',
            agent: '',
            caseNumber: '',
            decisionNo: '',
            customsOffice: '',
            plaintiffOrDefendantOrOppositeSide: '',               
            relatedSelect: 0,
            relatedOption: [],
            matterTypeSelect: 0,
            matterTypeOption: [],
            lawMatterSubTypeSelect: 0,
            lawMatterSubTypeOption: [],
            lawMatterCaseTypeSelect: 0,
            lawMatterCaseTypeOption: [],
            firstLawyerSelect: 0,
            secondLawyerSelect: 0,
            firstLawyerOption: [],
            secondLawyerOption: [],
            recordDateEnd: null,
            recordDateStart: null,
            agentReferenceNo: '',
            operationRecordNo: '',
            lawMatterStatusSelect: 0,
            lawMatterStatusOption: [],
            lawCourtSelect: 0,
            lawCourtOption: [],
        }
    },
    mounted() {
        this.getMatterList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawMatter'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getMatterList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getMatterList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.matterFormModal.showInfo(id)
        },
        getMatterList() {
            this.rows = []
            const controller = {
                name: 'LawMatter'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                lawMatterTypeId: this.matterTypeSelect,
                employeeId: this.relatedSelect,
                matterNo: this.matterNo,
                agent: this.agent,
                client: this.client,
                lawMatterSubTypeId: this.lawMatterSubTypeSelect,
                lawMatterCaseTypeId: this.lawMatterCaseTypeSelect,
                recordDateStart: this.recordDateStart,
                recordDateEnd: this.recordDateEnd,
                operationRecordNo: this.operationRecordNo,
                agentReferenceNo: this.agentReferenceNo,
                firstLawyerId: this.firstLawyerSelect,
                secondLawyerId: this.secondLawyerSelect,
                lawMatterStatusId: this.lawMatterStatusSelect,
                lawCourtId: this.lawCourtSelect,
                caseNumber: this.caseNumber,
                decisionNo: this.decisionNo,
                office: this.customsOffice,
                plaintiffOrDefendantOrOppositeSide: this.plaintiffOrDefendantOrOppositeSide,
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.relatedOption = response.data.employeeList
                    this.matterTypeOption = response.data.lawMatterTypeList
                    response.data.lawMatterList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.relatedOption.splice(0, 0, this.$nullSelected()),
                        this.matterTypeOption.splice(0, 0, this.$nullSelected())
                    //STOCK-1122
                    this.lawMatterSubTypeOption = response.data.lawMatterSubTypeList
                    this.lawMatterSubTypeOption.splice(0, 0, this.$nullSelected())
                    //
                    //STOCK-1133
                    this.lawMatterCaseTypeOption = response.data.lawMatterCaseTypeList
                    this.lawMatterCaseTypeOption.splice(0, 0, this.$nullSelected())
                    //
                    //STOCK-1241
                    this.firstLawyerOption = response.data.lawMatterFirstLawyerList
                    this.firstLawyerOption.splice(0, 0, this.$nullSelected())
                    this.secondLawyerOption = response.data.lawMatterSecondLawyerList
                    this.secondLawyerOption.splice(0, 0, this.$nullSelected())
                    this.lawMatterStatusOption = response.data.lawMatterStatusList
                    this.lawMatterStatusOption.splice(0, 0, this.$nullSelected())
                    //
                    this.lawCourtOption = response.data.lawCourtList
                    this.lawCourtOption.splice(0, 0, this.$nullSelected())                   
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawMatter',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Matter", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
