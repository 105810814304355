<template>
<div>
    <!-- modal -->
    <b-modal id="matterCautionFormModal" ref="matterCautionFormModal" centered size="xl" no-close-on-backdrop :title=" $t('matterCaution.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-order-date-input">{{ $t('matterCaution.order_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCaution.order_date')" size="sm" v-model="orderDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-order-stage-input">{{ $t('matterCaution.order_stage') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-caution-order-stage-input" size="sm" v-model="orderStageSelect" value-field="id" text-field="name" :options="orderStageOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-enforcement-date-input">{{ $t('matterCaution.enforcement_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCaution.enforcement_date')" size="sm" v-model="enforcementDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-row class="mt-1">
                    <div class="demo-inline-spacing">
                        <b-form-checkbox v-model="againstUse" name="checkbox-validation">
                            {{ $t('matterCaution.against_use') }}
                        </b-form-checkbox>
                        <b-form-checkbox v-model="againstPreventionOfTransfer" name="checkbox-validation">
                            {{ $t('matterCaution.against_prevention_of_transfer') }}
                        </b-form-checkbox>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-enforcement-indemnity-input">{{ $t('matterCaution.enforcement_indemnity') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-caution-enforcement-indemnity-input" v-model="enforcementIndemnity" type="Number" maxlength="300" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-goods-quantity-input">{{ $t('matterCaution.goods_quantity') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-caution-goods-quantity-input" v-model="goodsQuantity" type="Number" maxlength="300" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-city-input">{{ $t('matterCaution.city') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-caution-city-input" size="sm" v-model="citySelect" value-field="id" text-field="name" :options="cityOption" @change="getDistrictList()" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-district-input">{{ $t('matterCaution.district') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-caution-district-input" size="sm" v-model="districtSelect" value-field="id" text-field="name" :options="districtOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-deposit-date-input">{{ $t('matterCaution.deposit_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCaution.deposit_date')" size="sm" v-model="depositDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-caution-collected-date-input">{{ $t('matterCaution.collected_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCaution.collected_date')" size="sm" v-model="collectedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-row class="mt-1">
                    <div class="demo-inline-spacing">
                        <b-form-checkbox v-model="cash" name="checkbox-validation">
                            {{ $t('matterCaution.cash') }}
                        </b-form-checkbox>
                        <b-form-checkbox v-model="guaranteeLetter" name="checkbox-validation">
                            {{ $t('matterCaution.guarantee_letter') }}
                        </b-form-checkbox>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-caution-goods-input">{{ $t('matterCaution.goods') }}</label>
                    <b-form-group>
                        <b-form-textarea id="matter-caution-goods-input" :placeholder=" $t('matterCaution.goods')" v-model="goods" rows="3" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterCautionList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId: 0,
            orderDate: null,
            orderStageSelect: 0,
            districtList: [],
            orderStageOption: [],
            enforcementDate: null,
            enforcementIndemnity: '',
            goodsQuantity: '',
            citySelect: 0,
            cityOption: [],
            districtSelect: 0,
            districtOption: [],
            depositDate: null,
            collectedDate: null,
            goods: '',
            cash: false,
            guaranteeLetter: false,
            againstPreventionOfTransfer: false,
            againstUse: false,
            lawMatterCautionPaymentTypeId: 0,
            lawMatterCautionTypeId: 0
        }
    },
    methods: {
        getDistrictList() {
            if (this.citySelect > 0) {
                alert(this.citySelect)
                this.districtOption = this.districtList.filter(p => p.cityId == this.citySelect)
                this.districtSelect = 0;
                this.districtOption.splice(0, 0, this.$nullSelected())
            }
        },
        showInfo(id, lawMatterId) {
            this.$refs['matterCautionFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterCaution'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.orderDate = response.data.lawMatterCaution.orderDate
                    this.orderStageSelect = response.data.lawMatterCaution.lawOrderStageId
                    this.orderStageOption = response.data.lawOrderStageList
                    this.enforcementDate = response.data.lawMatterCaution.enforcementDate
                    this.enforcementIndemnity = response.data.lawMatterCaution.enforcementIndemnity
                    this.goodsQuantity = response.data.lawMatterCaution.goodsQuantity
                    this.citySelect = response.data.lawMatterCaution.cityId
                    this.cityOption = response.data.cityList
                    this.districtSelect = response.data.lawMatterCaution.districtId
                    this.depositDate = response.data.lawMatterCaution.depositDate
                    this.collectedDate = response.data.lawMatterCaution.collectedDate
                    this.goods = response.data.lawMatterCaution.goods
                    this.districtList = response.data.districtList
                    this.orderStageOption.splice(0, 0, this.$nullSelected())
                    this.cityOption.splice(0, 0, this.$nullSelected())
                    if (response.data.lawMatterCaution.cityId != 0) {
                        this.districtOption = this.districtList.filter(p => p.cityId == response.data.lawMatterCaution.cityId)
                        this.districtSelect = response.data.lawMatterCaution.districtId;
                        this.districtOption.splice(0, 0, this.$nullSelected())
                    }
                    // STOCK-1381
                    if (response.data.lawMatterCaution.lawMatterCautionPaymentTypeId != 0) {
                        if (response.data.lawMatterCaution.lawMatterCautionPaymentTypeId == 1) {
                            this.cash = true;
                            this.guaranteeLetter = false;
                        } else if (response.data.lawMatterCaution.lawMatterCautionPaymentTypeId == 2) {
                            this.guaranteeLetter = true;
                            this.cash = false;
                        }
                    } else {
                        this.cash = false;
                        this.guaranteeLetter = false;
                    }
                    if (response.data.lawMatterCaution.lawMatterCautionTypeId != 0) {
                        if (response.data.lawMatterCaution.lawMatterCautionTypeId == 1) {
                            this.againstUse = true;
                            this.againstPreventionOfTransfer = false;

                        } else if (response.data.lawMatterCaution.lawMatterCautionTypeId == 2) {
                            this.againstPreventionOfTransfer = true
                            this.againstUse = false;
                        }
                    } else {
                        this.againstUse = false;
                        this.againstPreventionOfTransfer = false;
                    }
                    //END CODE
                } else {
                    this.$bvModal.hide('matterCautionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.againstUse == true) {
                this.lawMatterCautionTypeId = 1;
            }
            if (this.againstPreventionOfTransfer == true) {
                this.lawMatterCautionTypeId = 2;

            }
            if (this.cash == true) {
                this.lawMatterCautionPaymentTypeId = 1;
            }
            if (this.guaranteeLetter == true) {
                this.lawMatterCautionPaymentTypeId = 2;
            }
            const data = {
                id: this.id,
                orderDate: this.orderDate,
                lawOrderStageId: this.orderStageSelect,
                enforcementDate: this.enforcementDate,
                enforcementIndemnity: this.enforcementIndemnity,
                goodsQuantity: this.goodsQuantity,
                cityId: this.citySelect,
                districtId: this.districtSelect,
                depositDate: this.depositDate,
                collectedDate: this.collectedDate,
                goods: this.goods,
                lawMatterId: this.lawMatterId,
                lawMatterCautionPaymentTypeId: this.lawMatterCautionPaymentTypeId,
                lawMatterCautionTypeId: this.lawMatterCautionTypeId
            }
            const controller = {
                name: 'LawMatterCaution'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterCautionFormModal')
                    this.getMatterCautionList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
