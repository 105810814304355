<template>
<div>
    <!-- modal -->
    <b-modal id="matterPlaintiffFormModal" ref="matterPlaintiffFormModal" centered size="lg" no-close-on-backdrop :title="title" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-plaintiff-name-input">{{ $t('matterPlaintiff.name') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-plaintiff-name-input" size="sm" v-model="name" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterPlaintiffList: { type: Function },
        lawMatterId: { type: Number},
        plaintiffOrLibelee: {type:String}
    },
    data() {
        return {
            id: 0,
            name: '',
            title: ''
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['matterPlaintiffFormModal'].show()
            if(this.plaintiffOrLibelee == 1){
                this.title = this.$t('matterPlaintiff.libelee')
            } else if(this.plaintiffOrLibelee == 2){
                this.title = this.$t('matterPlaintiff.plaintiff')
            } 
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterPlaintiff'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.lawMatterPlaintiff.name
                } else {
                    this.$bvModal.hide('matterPlaintiffFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                lawMatterId : this.lawMatterId    

            }
            const controller = {
                name: 'LawMatterPlaintiff'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterPlaintiffFormModal')
                    this.getMatterPlaintiffList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
