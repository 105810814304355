<template>
<div>
    <!-- modal -->
    <b-modal id="lawNoteToBeOnInvoiceFormModal" ref="lawNoteToBeOnInvoiceFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawNoteToBeOnInvoice.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="law-note-to-be-on-invoice-code-input">{{ $t('lawNoteToBeOnInvoice.code') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('lawNoteToBeOnInvoice.code')" rules="required">
                                <b-form-input id="law-note-to-be-on-invoice-code-input" size="sm" v-model="code" maxlength="3" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="currency-input">{{ $t('lawNoteToBeOnInvoice.currency') }}</label>
                        <b-form-group>
                            <b-form-select id="currency-input" size="sm" v-model="currencySelect" value-field="id" text-field="code" :options="currencyOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <validation-provider #default="{ errors }" :name=" $t('lawNoteToBeOnInvoice.note')" rules="required">
                        <label for="law-note-to-be-on-invoice-note-input">{{ $t('matterNote.note') }}</label>
                        <b-form-textarea size="sm" id="law-note-to-be-on-invoice-note-input" v-model="note" rows="3" max-rows="3" maxlength="500" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawNoteToBeOnInvoiceList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            code: '',
            note: '',
            currencySelect: 0,
            currencyOption: [],
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['lawNoteToBeOnInvoiceFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawNoteToBeOnInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.currencyOption = response.data.currencyList
                    this.code = response.data.lawNoteToBeOnInvoice.code
                    this.note = response.data.lawNoteToBeOnInvoice.note
                    if (response.data.lawNoteToBeOnInvoice.currencyId > 0) {
                        this.currencySelect = response.data.lawNoteToBeOnInvoice.currencyId
                    } else {
                        this.currencySelect = 0;
                    }
                    this.currencyOption.splice(0, 0, this.$nullSelected('code'))

                } else {
                    this.$bvModal.hide('lawNoteToBeOnInvoiceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                code: this.code,
                note: this.note,
                currencyId: this.currencySelect
            }
            const controller = {
                name: 'LawNoteToBeOnInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('lawNoteToBeOnInvoiceFormModal')
                            this.getLawNoteToBeOnInvoiceList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
