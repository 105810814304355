<template>
    <div>
        <matter-form ref="lawMatterForm"></matter-form>               
        <invoice-form :getDailyworkReportList="getDailyworkReportList" ref="invoiceForm"></invoice-form>
        <dailywork-form :getDailyworkReportList="getDailyworkReportList" ref="dailyworkForm"></dailywork-form>
        <div class="custom-search">
            <b-row>
                <b-col class="col-lg-12 mb-1">
                    <label>{{ $t('dailyworkReport.agent') }}</label>
                    <v-select id="agent" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch"></v-select>
                </b-col>
            </b-row>
            <b-row class="align-items-center">
                <b-col class="d-flex align-items-center col-lg-8">
                    <div class="col-lg-4 mr-1">
                        <label>{{ $t('dailyworkReport.recordNo') }}</label>
                        <b-form-input size="sm" v-model="recordNo" :placeholder="$t('dailyworkReport.recordNo')" type="text" class="d-inline-block" />
                    </div>
                    <div class="col-lg-4 mr-1">
                        <label>{{ $t('dailyworkReport.employee') }}</label>
                        <b-form-select v-model="employeeSelect" size="sm" value-field="id" text-field="name" :options="employeeOption" />
                    </div>
                    <div class="col-lg-4 ">
                        <label >{{ $t('dailyworkReport.billingStatus') }}</label>
                        <b-form-select  v-model="lawBillingStatusSelect" size="sm" value-field="id" text-field="name" :options="lawBillingStatusOption" />
                    </div>
                </b-col>
                <b-col class="d-flex justify-content-end col-lg-4 mt-1">
                    <b-button class="mr-1" style="float:right;" size="sm" v-on:keyup.enter="getDailyworkReportList()" variant="outline-primary" @click="getDailyworkReportList()">{{$t('dailyworkReport.list')}}</b-button>
                    <b-button variant="outline-primary" size="sm" @click.prevent="showInvoiceForm()"> {{$t('dailywork.invoice')}}</b-button>
                </b-col>
            </b-row>
        </div>
        <br/>
        <!-- table -->
        <vue-good-table id="dailyworkReportTable" ref="dailyworkReportTable" :columns="columns" :rows="rows" :sort-options="{
            enabled: false,
          }" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm}" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
              enabled: true,
              perPage: pageLength
            }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>                
            <!-- Row: Table Row -->
            <template slot="table-row" slot-scope="props" >
                <span v-if="props.column.field === 'isSelected'">
                    <b-form-checkbox :disabled="props.row.lawBillingStatusId == 2"  @change.native="changeChecked(props.row.id, $event.target)" text-field="name" class="demo-inline-spacing"/>
                </span>
                <span v-else-if="props.column.field === 'price'">
                    <span>
                        {{  $formatMoney(props.row.price) }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'description'" class="text-nowrap" v-b-tooltip.html :title="props.row.description">
                    {{props.row.description.length > 50 ? props.row.description.substring(0, 50) + '...' : props.row.description }}
                </span>
                <span v-else-if="props.column.field === 'lawMatterRecordNo'" class="text-nowrap">
                    <b-badge variant="primary" style="cursor: pointer;" @click="showLawMatterForm(props.row)">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.lawMatterRecordNo }}</span>
                    </b-badge>
                </span>
                <span v-else-if="props.column.field === 'lawBillingStatus'" style="cursor:pointer" :title="props.row.lawBillingStatus">
                    <feather-icon v-if="props.row.lawBillingStatusId == 1" color="green" icon="CircleIcon" />
                    <feather-icon v-if="props.row.lawBillingStatusId == 2" color="red" icon="CircleIcon" />
                    <feather-icon v-if="props.row.lawBillingStatusId == 3" color="orange" icon="CircleIcon" />
                </span>
                <span v-else-if="props.column.field === 'process'">
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                        class="btn-icon mb-1 ml-1" size="sm" @click="openDailyworkForm(props.row)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </template>            
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                        </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDailyworkReportList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                id: 0,
                employeeOption: [],
                employeeSelect: 0,
                lawBillingStatusOption: [],
                lawBillingStatusSelect: 0,     
                agentSelect: 0,
                agentOption: [],           
                recordNo:'',
                clientAccountingId:0,
                columns: [
                    {
                        label: '',
                        field: `isSelected`,
                    },
                    {
                        label: this.$t('dailyworkReport.recordNo'),
                        field: `lawMatterRecordNo`,
                    },
                    {
                        label: this.$t('dailyworkReport.serviceCode'),
                        field: `lawServiceCode`,
                    },
                    {
                        label: this.$t('dailyworkReport.invoiceAgent'),
                        field: `invoiceClientAccounting`,
                    },
                    {
                        label: this.$t('dailyworkReport.ownerClient'),
                        field: `ownerClient`,
                    },
                    {
                        label: this.$t('dailyworkReport.shortName'),
                        field: `shortName`,
                    },
                    {
                        label: this.$t('dailyworkReport.description'),
                        field: `description`,
                    },
                    {
                        label: this.$t('dailyworkReport.date'),
                        field: `recordDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('dailyworkReport.amount'),
                        field: `price`,
                    },
                    {
                        label: this.$t('dailyworkReport.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('dailyworkReport.employee'),
                        field: `employee`,
                    },
                    {
                        label: this.$t('dailyworkReport.billingStatus'),
                        field: `lawBillingStatus`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '5em'
                    },
                ],
                rows: [],
                total: 0,
                pageLength: 20,
                pageNo: 1,
                dir: false,
                searchTerm: ''
            }
        },
        mounted() {
            const controller = { 
                name: 'LawDailywork',
                actionName:'GetLawDailyworkReportInfo'
            }
            const payload = { controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.lawBillingStatusSelect = 0
                    this.lawBillingStatusOption = response.data.lawBillingStatusList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.lawBillingStatusOption.splice(0, 0, this.$nullSelected())
                    this.employeeSelect = localStorage.getItem('EmployeeId')
                    this.getDailyworkReportList()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        methods: {
            openDailyworkForm(row){
                this.$refs.dailyworkForm.showInfo(row.id, row.recordId, row.lawMatterRecordNo,row.shortName)
            },
            changeChecked(id, target){
                const status = target.checked
                if(status) {
                    var agentControl = this.rows.filter(p=> p.isSelected == true)
                    if(agentControl.length > 0) {
                        var dailyworkControl = this.rows.find(p=> p.id == id)
                        if(agentControl.filter(p => p.invoiceClientAccountingId == dailyworkControl.invoiceClientAccountingId).length == 0){
                            this.$WarningAlert(this.$t('others.NotSameAgentWarning'))
                            target.checked = false;    
                            return
                        }
                    }
                }
                this.rows = this.rows.map((dailyWork) => {
                    if (dailyWork.id === id) {
                        return {
                            ...dailyWork,
                            isSelected : status
                        };
                    }
                    return dailyWork;
                });
            },
            onSearch(search, loading) {
                if (search.length) {
                    loading(true);
                    this.search(loading, search, this);
                }
            },
            search(loading, search, vm) {
                if (search.length > 2) {
                    const data = {
                        clientAccountingId: 0,
                        clientName: search
                    }
                    const controller = {
                        name: 'LawClient',
                        actionName: 'GetClientAccountingByClientName'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        this.agentOption = response.data.clientList
                        loading(false)
                    })
                }
            },
            setClientId(event) {
                this.clientAccountingId = event.clientAccountingId
            },
            getDailyworkReportList() {
                this.rows = []
                const controller = {
                    name: 'LawDailywork',
                    actionName: 'GetLawDailyworkReport'
                }
                const data = {
                    employeeId: this.employeeSelect,
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    lawMatterRecordNo: this.recordNo,
                    lawBillingStatusId: this.lawBillingStatusSelect,
                    invoiceClientAccountingId: this.clientAccountingId
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        this.rows = response.data.lawDailyworkList
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })
                
            },
            showLawMatterForm(row) {
                this.$refs.lawMatterForm.showInfo(row.recordId)            
            },
            showInvoiceForm(){
                var dailyworks = this.rows.filter(i => i.isSelected === true);
                if(dailyworks.length == 0){
                    this.$ErrorSaveAlert()
                    return
                }
                this.$refs.invoiceForm.showInfoFromDailyWorkList(dailyworks)
            },
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    