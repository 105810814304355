<template>
    <div>
      <!-- modal -->
      <b-modal id="expertFormModal" ref="expertFormModal" centered size="lg" no-close-on-backdrop :title=" $t('expert.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="expert-name-input">{{ $t('expert.name') }}</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('expert.name')" rules="required" >
                      <b-form-input id="expert-code-input" size="sm" v-model="name" maxlength="100" :state="errors.length > 0 ? false:null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-form>
            </b-col>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="expert-title-input">{{ $t('expert.title') }}</label>
                  <b-form-group>
                    <b-form-input id="expert-title-input" size="sm" v-model="title" maxlength="100"/>
                  </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="expert-city-input">{{ $t('expert.city') }}</label>
                    <b-form-select size="sm" v-model="citySelect" value-field="id" text-field="name" :options="cityOption" />
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="expert-field-of-expertise-input">{{ $t('expert.field_of_expertise') }}</label>
                  <b-form-group>
                    <b-form-input id="expert-field-of-expertise-input" size="sm" v-model="fieldOfExpertise" maxlength="500"/>
                  </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="expert-institution-input">{{ $t('expert.institution') }}</label>
                    <b-form-group>
                      <b-form-input id="expert-institution-input" size="sm" v-model="institution" maxlength="100"/>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="expert-phone-number-input">{{ $t('expert.phone_number') }}</label>
                  <b-form-group>
                    <b-form-input id="expert-phone-number-input" size="sm" v-model="phoneNumber" maxlength="25"/>
                  </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="expert-email-input">{{ $t('expert.email') }}</label>
                    <b-form-group>
                      <b-form-input id="expert-email-input" size="sm" v-model="email" maxlength="50"/>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="expert-is-black-list-input">{{ $t('expert.is_black_list') }}</label>
                    <b-form-checkbox name="expert-is-black-list-input" id="expert-is-black-list-input" class="custom-control-success" v-model="isBlackList" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                  </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-12">
                <label for="expert-note-input">{{ $t('expert.note') }}</label>
                <b-form-textarea id="expert-note-input" :placeholder=" $t('expert.note')" size="sm" rows="3" v-model="note" />
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getExpertList: {type: Function},
    },
    data() {
      return {
        id: 0,
        name: '',
        title: '',
        citySelect: 0,
        cityOption: [],
        fieldOfExpertise: '',
        institution: '',
        phoneNumber: '',
        email: '',
        isBlackList: false,
        note: ''
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['expertFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'LawCourtExpert'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.lawCourtExpert.name
                this.title = response.data.lawCourtExpert.title
                this.citySelect = response.data.lawCourtExpert.cityId
                this.cityOption = response.data.cityList
                this.fieldOfExpertise = response.data.lawCourtExpert.fieldOfExpertise
                this.institution = response.data.lawCourtExpert.institution
                this.phoneNumber = response.data.lawCourtExpert.phoneNumber
                this.email = response.data.lawCourtExpert.email
                this.isBlackList = response.data.lawCourtExpert.isBlackList
                this.note = response.data.lawCourtExpert.note
                this.cityOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('expertFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.name, title: this.title, cityId: this.citySelect, fieldOfExpertise: this.fieldOfExpertise, institution: this.institution,
            phoneNumber: this.phoneNumber, email: this.email, isBlackList: this.isBlackList, note: this.note}
        const controller = {name : 'LawCourtExpert'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$store.dispatch('moduleAuth/save',payload).then((response) => {
              if(response.data.resultStatus == true){
                  this.$bvModal.hide('expertFormModal')
                  this.getExpertList()
                  this.$SaveAlert()
              } else {
                  this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  