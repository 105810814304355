<template>
<div>
    <!-- modal -->
    <b-modal id="lawMatterCourtExpertFormModal" ref="lawMatterCourtExpertFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawMatterCourtExpert.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <validation-provider v-slot="{ errors }" :name="$t('lawMatterCourtExpert.expert_name')" :rules="{ excluded:0}" >
                            <label for="law-matter-court-expert-input">{{ $t('lawMatterCourtExpert.expert_name') }}</label>
                            <b-form-select size="sm" v-model="lawCourtExpertSelect" :options="lawCourtExpertOption" value-field="id" name="law-matter-court-expert-input" text-field="name" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{  errors[0]  }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterCourtExpertList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId: 0,
            lawCourtExpertSelect: 0,
            lawCourtExpertOption: [],
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['lawMatterCourtExpertFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterCourtExpert'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawCourtExpertSelect = response.data.lawMatterCourtExpert.lawCourtExpertId
                    this.lawCourtExpertOption = response.data.lawCourtExpertList
                    this.lawCourtExpertOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('lawMatterCourtExpertFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = { id: this.id, lawCourtExpertId: this.lawCourtExpertSelect, lawMatterId: this.lawMatterId}
            const controller = { name: 'LawMatterCourtExpert'}
            const payload = { data: data, controller: controller}
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$bvModal.hide('lawMatterCourtExpertFormModal')
                        this.getMatterCourtExpertList()
                        this.$SaveAlert()
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }})
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
