var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"invoiceCodeReportDetailFormModal",attrs:{"id":"invoiceCodeReportDetailFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('invoiceCodeReportDetail.info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-name-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.name')))]),_c('validation-provider',{attrs:{"name":_vm.$t('invoiceCodeReportDetail.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice-code-report-detail-name-input","size":"sm","maxlength":"250"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-code-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.code')))]),_c('validation-provider',{attrs:{"name":_vm.$t('invoiceCodeReportDetail.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.id > 0,"id":"invoice-code-report-detail-code-input","size":"sm","maxlength":"20"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-vat-rate-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.vat_rate')))]),_c('b-form-input',{attrs:{"disabled":_vm.id > 0,"id":"invoice-code-report-detail-vat-rate-input","size":"sm","maxlength":"250"},model:{value:(_vm.vatRate),callback:function ($$v) {_vm.vatRate=$$v},expression:"vatRate"}})],1)],1),_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-accounting-no-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.accounting_no')))]),_c('b-form-input',{attrs:{"disabled":_vm.id > 0,"id":"invoice-code-report-detail-accounting-no-input","size":"sm","maxlength":"20"},model:{value:(_vm.accountingNo),callback:function ($$v) {_vm.accountingNo=$$v},expression:"accountingNo"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-service-type-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.service_type')))]),_c('validation-provider',{attrs:{"name":_vm.$t('invoiceCodeReportDetail.service_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.id > 0,"size":"sm","value-field":"id","text-field":"name","options":_vm.serviceTypeOption},model:{value:(_vm.serviceTypeSelect),callback:function ($$v) {_vm.serviceTypeSelect=$$v},expression:"serviceTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-costing-type-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.costing_type')))]),_c('validation-provider',{attrs:{"name":_vm.$t('invoiceCodeReportDetail.costing_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.id > 0,"size":"sm","value-field":"id","text-field":"name","options":_vm.costingTypeOption},model:{value:(_vm.costingTypeSelect),callback:function ($$v) {_vm.costingTypeSelect=$$v},expression:"costingTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-description-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.description')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"invoice-code-report-detail-description-input","placeholder":_vm.$t('invoiceCodeReportDetail.description'),"rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"invoice-code-report-detail-accounting-description-input"}},[_vm._v(_vm._s(_vm.$t('invoiceCodeReportDetail.accounting_description')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"invoice-code-report-detail-accounting-description-input","placeholder":_vm.$t('invoiceCodeReportDetail.accounting_description'),"rows":"3"},model:{value:(_vm.accountingDescription),callback:function ($$v) {_vm.accountingDescription=$$v},expression:"accountingDescription"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }