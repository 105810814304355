<template>
<div>
    <!-- modal -->
    <b-modal id="lawDailyworkDescriptionSelectionFormModal" ref="lawDailyworkDescriptionSelectionFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawDailyworkDescription.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="law-dailywork-name-input">{{ $t('lawDailyworkDescription.name') }}</label>
                    <b-form-group>
                        <validation-provider :name=" $t('lawDailyworkDescription.name')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <v-select :state="errors.length > 0 ? false:null" id="law-dailywork-name-input" size="sm" v-model="lawDailyworkDescriptionSelect" label="name" :options="lawDailyworkDescriptionOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawDailyworkDescriptionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            name: '',
            lawDailyworkDescriptionOption: [],
            lawDailyworkDescriptionSelect: 0,
            type: '',
            lawDailyworkDescriptionEmitValue: []
        }
    },
    methods: {
        clear() {
            this.lawDailyworkDescriptionSelect = 0;
        },
        showInfo(id) {
            this.$refs['lawDailyworkDescriptionSelectionFormModal'].show()
            const controller = {
                name: 'LawDailyworkDescription'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawDailyworkDescriptionSelect = 0
                    this.lawDailyworkDescriptionOption = response.data.lawDailyworkDescriptionList
                } else {
                    this.$bvModal.hide('lawDailyworkDescriptionSelectionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async addOrUpdate() {
            this.lawDailyworkDescriptionEmitValue =  this.lawDailyworkDescriptionSelect
            this.$emit("lawDailyworkDescriptionEmitValue", this.lawDailyworkDescriptionEmitValue)
            //i wrote promise sentence because we'll waiting for emit process 20.11.2024 Furkan
            await new Promise(resolve => setTimeout(resolve, 500));
            this.$bvModal.hide('lawDailyworkDescriptionSelectionFormModal')
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.b-modal {
    z-index: 1050 !important;
}
</style>
