import axios from '../../axios'
import store from '../../store/index'
import router from '../../router'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

const server = 'https://webstockservice.stock.com.tr:8088' // Stock Server
//const server = 'http://37.247.100.212:8088' // Vebiz Server
//const server = 'http://localhost:5000' // Mustafa Lokal
//const server = 'https://localhost:44312' //Nevzat Lokal


function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.request.use(function (config) {
      if (localStorage.getItem('accessToken') != null || config.url.indexOf('Authenticate') > -1 || config.url.indexOf('ForgottenPasswordMail') > -1 || config.url.indexOf('ChangeForgottenPassword') > -1) {
        // Do something before request is sent
        return config;
      }
      else {
        router.push({ name: 'login' })
        return Promise.reject(error)
      }
    })

    axios.interceptors.response.use(function (response) {
      return response
    },
      function (error) {
        const { config, response } = error
        const originalRequest = config
        if (response) {
          if (response.status === 401) {
            router.push({ name: 'login' })
            if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true
              store.dispatch('moduleAuth/fetchAccessToken')
                .then((access_token) => {
                  isAlreadyFetchingAccessToken = false
                  onAccessTokenFetched(access_token)
                })
            }

            const retryOriginalRequest = new Promise((resolve) => {
              addSubscriber(access_token => {
                originalRequest.headers.Authorization = `JWT ${access_token}`
                resolve(axios(originalRequest))
              })
            })

            return retryOriginalRequest
          }
          else if (response.status === 400) {
            if (response.data == null) {
              response.data = { resultStatus: false, resultMessage: 'Sunucuya ulaşılamadığı için işleminizi gerçekleştiremiyoruz.' };
              router.push({ name: 'login' })
              return Promise.reject(error)
            }
            else {
              response.data = { resultStatus: false, resultMessage: response.data.resultMessage };
              return response
            }
          }
          else if (response.status === 403) {
            response.data = { resultStatus: false, resultMessage: 'Bu işlemi yapmak için yetkiniz yoktur.' };
            return response
          }
        }
        return Promise.reject(error)
      })
  },
  login(username, password) {
    return axios.post(`${server}/Employee/AuthenticateAttorneyInLaw`, {
      username: username,
      password: password
    })
  },
  getInfo(controllerName, data) {
    return axios.post(`${server}/${controllerName}/${'Get' + controllerName + 'Info'}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  getList(controllerName) {
    return axios.get(`${server}/${controllerName}/${'Get' + controllerName + 'List'}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  getListWithAction(controllerName,actionName) {
    return axios.get(`${server}/${controllerName}/${'Get' +actionName}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  getPostList(controllerName, data) {
    return axios.post(`${server}/${controllerName}/${'Get' + controllerName + 'List'}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  delete(controllerName, data) {
    return axios.post(`${server}/${controllerName}/${'Delete' + controllerName}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  save(controllerName, data) {
    return axios.post(`${server}/${controllerName}/${'Save' + controllerName}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  download(controllerName, data) {
    return axios.post(`${server}/${controllerName}/${'Download' + controllerName}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  canExport(controllerName) {
    return axios.get(`${server}/${controllerName}/canExport`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  customService(controllerName, actionName, data) {
    return axios.post(`${server}/${controllerName}/${actionName}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
  customServiceGet(controllerName, actionName) {
    return axios.get(`${server}/${controllerName}/${actionName}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
  },
}
