<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8">
                <b-col>
                    <label for="expense-report-user-input"> {{ $t('expenseReport.user') }} </label>
                    <b-form-select id="expense-report-user-input" size="sm" v-model="userSelect" value-field="id" text-field="name" :options="userOption" class="mb-1" />
                </b-col>
                <b-col>
                    <label for="expense-report-start-date-input"> {{ $t('expenseReport.start_date') }} </label>
                    <b-form-datepicker id="expense-report-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('expenseReport.start_date')" size="sm" v-model="startDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
                <b-col>
                    <label for="expense-report-end-date-input"> {{ $t('expenseReport.end_date') }} </label>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('expenseReport.end_date')" size="sm" v-model="endDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getExpenseReportList()" v-on:keyup.enter="getExpenseReportList()">{{ $t('expenseReport.list') }}</b-button>
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="downloadExpensePdf()">{{ $t('expenseReport.expense_report') }}</b-button> 
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="expenseReportTable" ref="expenseReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: false,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'matterNo'" class="text-nowrap">
                    {{ $t('expenseReport.matter_no') }}
                </span>
                <span v-else-if="props.column.field === 'expense'">
                    {{ $t('expenseReport.task_expense') }}
                </span>
                <span v-else-if="props.column.field === 'agentName'" class="text-nowrap">
                    {{ $t('expenseReport.agent_name') }}
                </span>
                <span v-else-if="props.column.field === 'clientName'" class="text-nowrap">
                    {{ $t('expenseReport.client_name') }}
                </span>
                <span v-else-if="props.column.field === 'matterShortName'" class="text-nowrap">
                    {{ $t('expenseReport.short_name') }}
                </span>
                <span v-else-if="props.column.field === 'description'" class="text-nowrap">
                    {{ $t('expenseReport.description') }}
                </span>
                <span v-else-if="props.column.field === 'itemDate'" class="text-nowrap">
                    {{ $t('expenseReport.item_date') }}
                </span>
                <span v-else-if="props.column.field === 'untHrs'">
                    {{ $t('expenseReport.unt_hrs') }}
                </span>
                <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                    {{ $t('expenseReport.currency') }}
                </span>
                <span v-else-if="props.column.field === 'unitPrice'">
                    {{ $t('expenseReport.unit_price') }}
                </span>
                <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                    {{ $t('expenseReport.amount') }}
                </span>
                <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                    {{ $t('expenseReport.user_name') }}
                </span>
                <span v-else-if="props.column.field === 'billing'">
                    {{ $t('expenseReport.billing') }}
                </span>
            </template>

            <!-- Row: Table Row -->
            <template slot="table-row" slot-scope="props" >                
                <span v-if="props.column.field === 'lawBillingStatus'" style="cursor:pointer" :title="props.row.lawBillingStatus">
                    <feather-icon v-if="props.row.lawBillingStatusId == 1" color="green" icon="CircleIcon" />
                    <feather-icon v-if="props.row.lawBillingStatusId == 2" color="red" icon="CircleIcon" />
                    <feather-icon v-if="props.row.lawBillingStatusId == 3" color="orange" icon="CircleIcon" />
                </span>
            </template>   
    
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                userSelect: 0,
                userOption: [],
                startDate: null,
                endDate: null,
                columns: [{
                        label: this.$t('expenseReport.matter_no'),
                        field: `matterNo`,
                    },
                    {
                        label: this.$t('expenseReport.task_expense'),
                        field: `expense`,
                    },
                    {
                        label: this.$t('expenseReport.agent_name'),
                        field: `agentName`,
                    },
                    {
                        label: this.$t('expenseReport.client_name'),
                        field: `clientName`,
                    },
                    {
                        label: this.$t('expenseReport.short_name'),
                        field: `matterShortName`,
                    },
                    {
                        label: this.$t('expenseReport.description'),
                        field: `description`,
                    },
                    {
                        label: this.$t('expenseReport.item_date'),
                        field: `itemDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('expenseReport.unt_hrs'),
                        field: `count`,
                        formatFn: this.$formatMoney,
                    },
                    {
                        label: this.$t('expenseReport.currency'),
                        field: `currency`,                        
                    },
                    {
                        label: this.$t('expenseReport.unit_price'),
                        field: `unitPrice`,
                        formatFn: this.$formatMoney,
                    },
                    {
                        label: this.$t('expenseReport.amount'),
                        field: `amount`,
                        formatFn: this.$formatMoney,
                    },
                    {
                        label: this.$t('expenseReport.user_name'),
                        field: `userName`,
                    },
                    {
                        label: this.$t('expenseReport.billing'),
                        field: `lawBillingStatus`,
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.startDate = new Date();
            this.endDate = new Date();
            this.getExpenseReportList()
        },
        methods: {
            async downloadExpensePdf() {
                const controller = { name: 'LawReport', actionName: 'CreateLawExpensePdf' }
                const data = { lawExpenseReportList: this.rows, startDate: this.startDate, finishDate: this.endDate }
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("ExpenseReport", response.data.base64, 'pdf')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },            
            getExpenseReportList() {
                this.rows = []
                const controller = { name: 'LawReport', actionName: 'GetLawExpenseReportList' }
                const data = { employeeId: this.userSelect, startDate: this.startDate, endDate: this.endDate }
                const payload = { controller: controller, data : data }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        this.userOption = response.data.employeeList
                        this.userOption.splice(0, 0, this.$nullSelected("name"))
                        this.rows = response.data.lawExpenseReportList
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("ExpenseReport", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    