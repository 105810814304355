<template>
<div>
    <b-row class="align-items-center">
        <b-col class="d-flex justify-content-end col-lg-12 mb-1">
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('lawyerDailyworkReport.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="cancelledInvoiceTable" ref="cancelledInvoiceTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'accountingNo'" class="text-nowrap">
                {{ $t('cancelledInvoice.no') }}
            </span>
            <span v-else-if="props.column.field === 'agentName'" class="text-nowrap">
                {{ $t('cancelledInvoice.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'lawMatterRecordNo'" class="text-nowrap">
                {{ $t('cancelledInvoice.matter_no') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('cancelledInvoice.date') }}
            </span>
            <span v-else-if="props.column.field === 'lawMatterName'" class="text-nowrap">
                {{ $t('cancelledInvoice.matter_name') }}
            </span>
            <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                {{ $t('cancelledInvoice.currency_code') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('cancelledInvoice.total') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.proforma')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="downloadInvoicePdf(props.row.id)">
                        <feather-icon icon="FileTextIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.return_to_create_invoice')" variant="light" class="btn-icon btn-warning mb-1 ml-1" size="sm" @click="getReturnToCreateInvoiceInfo(props.row.id)">
                        <feather-icon icon="UnlockIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getCancelledInvoiceList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            total: 0,
            pageNo: 1,
            columns: [{
                    label: this.$t('cancelledInvoice.no'),
                    field: `debitNo`,
                },
                {
                    label: this.$t('cancelledInvoice.agent_name'),
                    field: `agentName`,
                },
                {
                    label: this.$t('cancelledInvoice.matter_no'),
                    field: `lawMatterRecordNo`,
                },
                {
                    label: this.$t('cancelledInvoice.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('cancelledInvoice.matter_name'),
                    field: `lawMatterName`,
                },
                {
                    label: this.$t('cancelledInvoice.currency_code'),
                    field: `currency`,
                },
                {
                    label: this.$t('cancelledInvoice.total'),
                    field: `total`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getCancelledInvoiceList()
    },
    methods: {
        async downloadInvoicePdf(id) {
            let data = {
                id: id
            }
            let controller = {
                name: 'LawInvoice'
            }
            let payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    data = {
                        lawInvoice: response.data.lawInvoice,
                        lawInvoiceDetailList: response.data.lawInvoiceDetailList
                    }
                    controller = {
                        name: 'LawInvoice',
                        actionName: 'DownloadProforma'
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })  
        },
        async getReturnToCreateInvoiceInfo(id) {
            this.$PrivateConfirm(this.$t("invoice.confirm_title"), this.$t("invoice.confirm_message"), "warning", this.$t("invoice.confirm_button_text"), this.$t("invoice.cancel_button_text")).then(result => {
                if (result.value) {
                    const data = {
                        lawInvoiceId: id,
                        lawInvoiceStatusId: 1
                    }
                    const controller = {
                        name: 'LawInvoice',
                        actionName: 'ChangeLawInvoiceStatus'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getCancelledInvoiceList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        getCancelledInvoiceList() {
            this.rows = []
            const controller = {
                name: 'LawReport',
                actionName: 'GetLawCancelledInvoicesReport'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                name: this.name,
                matterNo: this.matterNo,
                accountNumber: this.accountNumber,
                debitNote: this.debitNote,
                startTotal: this.startTotal,
                endTotal: this.endTotal,
                startDate: this.startDate,
                endDate: this.endDate
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.lawCancelledInvoicesReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawReport',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("CancelledInvoice", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

    
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
