<template>
<div>
    <!-- modal -->
    <b-modal id="invoiceFormModal" ref="invoiceFormModal" centered size="xl" no-close-on-backdrop :title="$t('invoice.invoice_info')" hide-footer>
        <matter-form ref="matterFormModal"></matter-form>
        <invoice-add-dailywork-form ref="invoiceAddDailyworkForm" :showInvoiceInfo="showInfo"></invoice-add-dailywork-form>
        <reminder-form ref="reminderForm"></reminder-form>
        <law-invoice-update-detail-form ref="lawInvoiceUpdateDetailForm" :showLawInvoiceInfo="showInfo"></law-invoice-update-detail-form>
        <validation-observer ref="simpleRules">

            <div class="custom-search mb-1">
                <b-row>
                    <b-col class="col-3">
                        <label>{{ $t('invoice.debit_no') }} : {{ this.debitNo }}</label>
                    </b-col>
                    <b-col class="col-9  d-flex justify-content-end">
                        <b-button v-if="lawInvoiceStatusId == 1" variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.show_invoice')" @click="downloadInvoicePdf()">
                            <feather-icon icon="FileTextIcon" size="10" class="align-middle" />
                        </b-button>
                        <b-button v-if="lawInvoiceStatusId == 1" variant="outline-warning" class="ml-1" size="sm" v-b-tooltip.html :title="$t('invoice.send_approval')" @click="sendApproval()">
                            <feather-icon icon="CompassIcon" size="10" class="align-middle" />
                        </b-button>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <!-- Grid bölünmesi -->
            <b-form-group :disabled="lawInvoiceStatusId > 1">
                <b-row>
                    <b-col class="col-lg-6">
                        <b-alert v-if="accountingNote != ''" variant="warning" show>
                            <div class="alert-body demo-inline-spacing" v-b-tooltip.hover.top="this.accountingNote">
                                {{ $t('invoice.accounting_note') }}
                            </div>
                        </b-alert>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-alert v-if="clientNote != ''" variant="warning" show>
                            <div class="alert-body demo-inline-spacing" v-b-tooltip.hover.top="this.clientNote">
                                {{ $t('invoice.client_note') }}
                            </div>
                        </b-alert>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-10">
                        <b-form>
                            <label for="invoice-agent-input">{{ $t('invoice.agent') }}</label>
                            <validation-provider :name="$t('invoice.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :state="errors.length > 0 ? false : null">
                                    <v-select disabled size="sm" id="invoice-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>

                    <b-col class="col-lg-2">
                        <b-form>
                            <label for="invoice-date-input">{{ $t('invoice.invoice_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('invoice.invoice_date')" size="sm" v-model="date" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form hidden="true">
                            <label for="invoice-type-input"> {{ $t('invoice.employee') }} </label>
                            <b-form-group>
                                <validation-provider :name=" $t('invoice.employee')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select :state="errors.length > 0 ? false:null" v-model="employeeSelect" value-field="id" text-field="name" size="sm" :options="employeeOption" @change="changeTabStatus()" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form hidden="true">
                            <label for="invoice-kdv-type-input"> {{ $t('invoice.lawKdvType') }} </label>
                            <b-form-group>
                                <validation-provider :name=" $t('invoice.lawKdvType')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select :state="errors.length > 0 ? false:null" v-model="lawKdvTypeSelect" value-field="id" text-field="name" size="sm" :options="lawKdvTypeOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row v-if="lawInvoiceDetailList.length > 0">
                    <b-col>
                        <label class="mt-2">{{ lawInvoiceDetailList[0].name }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- Sağ kısım devam eden form alanları -->
                    <b-col class="col-lg-12">
                        <b-row>
                            <b-col class="col-3">
                                <label class="mt-2" for="invoice-dailywork-input">{{ $t('invoice.invoice_item') }}</label>
                            </b-col>
                            <b-col class="col-9" style="text-align:right;">
                                <b-button variant="link" @click="showAddDailyworkForm()">{{ $t('invoice.dailywork_add') }}</b-button>
                            </b-col>
                        </b-row>
                        <draggable v-model="lawInvoiceDetailList" tag="ul" class="list-group list-group-flush cursor-move">
                            <b-list-group-item v-for="(item, index) in lawInvoiceDetailList" :key="index" tag="li">
                                <b-row>
                                    <b-col class="col-2">
                                        <span @click="showMatterForm(item.recordId)">{{ item.recordNo }}</span>
                                    </b-col>
                                    <b-col class="col-5">
                                        <span> {{ item.description }} </span>
                                    </b-col>
                                    <b-col class="col-2">
                                        <span>{{ item.employee}} </span>
                                    </b-col>
                                    <b-col class="col-2" style="text-align:right;">
                                        <span>{{ item.itemDefination}} </span>
                                        <span>{{ currency }} </span>
                                    </b-col>
                                    <b-col class="col-1">
                                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-05" size="sm" @click="showLawInvoiceUpdateDetail(item.id)">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>
                                        <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deletedInvoiceDetail(item.id)">
                                            <feather-icon icon="XIcon" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                        </draggable>
                        <b-list-group-item style="border:none;">
                            <b-row>
                                <b-col class="col-2"></b-col>
                                <b-col class="col-7" style="text-align:right;"> {{ $t('invoice.total') }} : </b-col>
                                <b-col class="col-2" style="text-align:right;">
                                    <span v-if="this.lawInvoiceDetailList != null" style="color: red; font-weight: bold">
                                        {{ $formatMoney(invoiceTotal) }}
                                    </span>
                                    <span style="color: red; font-weight: bold;">{{ currency}} </span>
                                </b-col>
                                <b-col class="col-1"> </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-col>
                </b-row>
            </b-form-group>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getInvoiceList: {
            type: Function
        },
        getDailyworkList: {
            type: Function
        },
        getDailyworkReportList: {
            type: Function
        }
    },
    data() {
        return {
            invoiceData: null,
            id: 0,
            date: null,
            debitNo: '',
            invoiceClientAccountingId: 0,
            clientId: 0,
            agentSelect: 0,
            agentOption: [],
            employeeSelect: 0,
            employeeOption: [],
            lawKdvTypeSelect: 0,
            lawKdvTypeOption: [],
            lawInvoiceStatusId: 0,
            clientAccountId: 0,
            currency: '',
            clientName: '',
            currencyId: 0,
            CurrencyCode: '',
            invoiceTotal: 0,
            columns: [{
                    label: this.$t('multiDailyworkSave.name'),
                    field: `recordNo`,
                },
                {
                    field: `name`,
                },
                {
                    field: `price`,
                }
            ],
            fields: [{
                    key: 'recordNo',
                    label: 'Record No',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true
                },
            ],
            lawInvoiceDetailList: [],
            accountingNote: '',
            clientNote: '',
        }
    },
    methods: {
        async getReminderInfo() {
            this.$refs.reminderForm.showInfo(0, this.id, 20)
        },
        showMatterForm(recordId) {
            this.$refs.matterFormModal.showInfo(recordId)
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'LawClient',
                    actionName: 'GetInvoiceClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        sendApproval() {
            this.$PrivateConfirm(this.$t("invoice.confirm_title"), this.$t("invoice.confirm_message"), "warning", this.$t("invoice.confirm_button_text"), this.$t("invoice.cancel_button_text")).then(result => {
                if (result.value) {
                    const data = {
                        lawInvoiceId: this.id,
                        lawInvoiceStatusId: 3
                    }
                    const controller = {
                        name: 'LawInvoice',
                        actionName: 'ChangeLawInvoiceStatus'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('invoiceFormModal')
                            if (this.getInvoiceList != null) {
                                this.getInvoiceList()
                            }
                            if (this.getDailyworkList != null) {
                                this.getDailyworkList()
                            }
                            if (this.getDailyworkReportList != null) {
                                this.getDailyworkReportList()
                            }
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        showAddDailyworkForm() {
            this.$refs.invoiceAddDailyworkForm.showInfo(this.id, this.invoiceClientAccountingId)
        },
        showInfo(id) {
            this.$refs['invoiceFormModal'].show()
            this.lawInvoiceDetailList = []
            this.accountingNote = ''
            this.clientNote = ''
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.invoiceData = response.data
                    this.lawInvoiceStatusId = response.data.lawInvoice.lawInvoiceStatusId
                    this.debitNo = response.data.lawInvoice.debitNo
                    this.clientName = response.data.lawInvoice.client
                    this.currency = response.data.lawInvoice.currencyCode
                    this.currencyId = response.data.lawInvoice.currencyId
                    this.date = response.data.lawInvoice.date
                    this.employeeOption = response.data.employeeList
                    this.employeeSelect = response.data.lawInvoice.employeeId
                    this.employeeOption.splice(0, 0, this.$nullSelected())

                    this.lawKdvTypeOption = response.data.lawKdvTypeList
                    this.lawKdvTypeSelect = response.data.lawInvoice.lawKdvTypeId
                    this.lawKdvTypeOption.splice(0, 0, this.$nullSelected())
                    this.invoiceTotal = response.data.lawInvoice.total

                    if (response.data.lawInvoiceDetailList != null) {
                        this.lawInvoiceDetailList = response.data.lawInvoiceDetailList
                    }
                    this.invoiceClientAccountingId = response.data.lawInvoice.invoiceClientAccountingId
                    if (response.data.lawInvoice.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.lawInvoice.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.agentOption = clientAccountingResponse.data.clientList
                            this.agentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.agentOption = []
                        this.agentSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('invoiceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showInfoFromDailyWorkList(dailyWorkList) {
            this.accountingNote = ''
            this.clientNote = ''
            this.id = 0
            const data = {
                employeeId: localStorage.getItem('EmployeeId'),
                lawInvoiceDetailList: dailyWorkList
            }
            const controller = {
                name: 'LawInvoice',
                actionName: 'GetInfoFromLawDailyWorkList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (this.getInvoiceList != null) {
                        this.getInvoiceList()
                    }

                    if (this.getDailyworkList != null) {
                        this.getDailyworkList()
                    }
                    if (this.getDailyworkReportList != null) {
                        this.getDailyworkReportList()
                    }

                    this.showInfo(response.data.lawInvoiceId)
                } else {
                    this.$bvModal.hide('invoiceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.lawInvoiceDetailList.length == 0) {
                this.$ErrorSaveAlert()
                return
            }

            const data = {
                lawInvoiceDto: {
                    id: this.id,
                    employeeId: this.employeeSelect,
                    invoiceClientAccountingId: this.invoiceClientAccountingId,
                    date: this.date,
                    lawKdvTypeId: this.lawKdvTypeSelect,
                    currencyId: this.currencyId,
                    currencyCode: this.currency,
                },
                lawInvoiceDetailList: this.lawInvoiceDetailList,
            }

            const controller = {
                name: 'LawInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.id = response.data.invoiceId
                            this.debitNo = response.data.debitNo
                            this.$bvModal.hide('invoiceFormModal')
                            if (this.getInvoiceList != null) {
                                this.getInvoiceList()
                            }
                            if (this.getDailyworkList != null) {
                                this.getDailyworkList()
                            }
                            if (this.getDailyworkReportList != null) {
                                this.getDailyworkReportList()
                            }
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },

        async downloadInvoicePdf() {
            const data = {
                lawInvoice: this.invoiceData.lawInvoice,
                lawInvoiceDetailList: this.invoiceData.lawInvoiceDetailList
            }
            const controller = {
                name: 'LawInvoice',
                actionName: 'DownloadProforma'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async deletedInvoiceDetail(id) {
            const data = {
                id: id
            }
            const controller = {
                name: 'LawInvoice',
                actionName: 'DeleteLawInvoiceDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                        }
                        if (this.getInvoiceList != null) {
                            this.getInvoiceList()
                        }
                        if (this.getDailyworkList != null) {
                            this.getDailyworkList()
                        }
                        if (this.getDailyworkReportList != null) {
                            this.getDailyworkReportList()
                        }

                        this.$bvModal.hide('invoiceFormModal')
                    })
                }
            })
        },
        showLawInvoiceUpdateDetail(lawInvoiceDetailId){
            this.$refs.lawInvoiceUpdateDetailForm.showInfo(lawInvoiceDetailId,this.id)
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
