<template>
    <div>
      <clients-accounting-form :getClientsList="getClientsList" :getClientsAccountingList="getClientsAccountingList" ref="clientsAccountingFormModal"></clients-accounting-form>
      <!-- table -->
      <vue-good-table 
      id="clientsAccountingTable"
      ref="clientsAccountingTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'jurisdiction'" class="text-nowrap">
            {{ $t('clientsAccounting.jurisdiction') }}
          </span>
          <span v-else-if="props.column.field === 'number'" class="text-nowrap">
            {{ $t('clientsAccounting.account_number') }}
          </span>
          <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
            {{ $t('clientsAccounting.exchange_code') }}
          </span>
          <span v-else-if="props.column.field === 'note'" class="text-nowrap">
            {{ $t('clientsAccounting.note') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.view')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">  
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      getClientsList: {type: Function},
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        clientsAccountingList: '',
        columns: [
        {
            label: this.$t('clientsAccounting.jurisdiction') ,
            field: `jurisdiction`,
          },
          {
            label: this.$t('clientsAccounting.account_number'),
            field: `number`,
          },
          {
            label: this.$t('clientsAccounting.exchange_code'),
            field: `currency`,
          },
          {
            label: this.$t('clientsAccounting.note'),
            field: `note`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getClientsAccountingList()
    },
    methods: {
      async getInfo(id) {
        this.$refs.clientsAccountingFormModal.showInfo(id, this.clientId)
      },
      getClientsAccountingList(clientId) {
        this.clientId = clientId
        this.rows = []
        const data = { id: this.clientId }
        const controller = { name: 'ClientAccounting', actionName:'GetClientAccountingListByClientId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.rows = response.data.clientAccountingList
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  