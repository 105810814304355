<template>
    <div>
        <expert-form :getExpertList="getExpertList" ref="expertFormModal"></expert-form>
        <b-row>
            <b-col class="col-12">
              <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search')" type="text"
                      class="d-inline-block" />
                  </div>
                </b-form-group>
              </div>
              <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-1" size="sm"  variant="outline-primary" @click="getInfo(0)">{{ $t('expert.add') }}</b-button>
                  </div>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        <!-- table -->
        <vue-good-table id="expertTable" ref="expertTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true, externalQuery: searchTerm}" :select-options="{ enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class',
            selectionText: 'rows selected', clearSelectionText: 'clear', disableSelectInfo: true, selectAllByGroup: true, }" :pagination-options="{
            enabled: true, perPage: pageLength }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'name'" class="text-nowrap">
                    {{ $t('expert.name') }}
                </span>
                <span v-else-if="props.column.field === 'title'" class="text-nowrap">
                    {{ $t('expert.title') }}
                </span>
                <span v-else-if="props.column.field === 'city'" class="text-nowrap">
                    {{ $t('expert.city') }}
                </span>
                <span v-else-if="props.column.field === 'fieldOfExpertise'" class="text-nowrap">
                    {{ $t('expert.field_of_expertise') }}
                </span>
                <span v-else-if="props.column.field === 'phoneNumber'" class="text-nowrap">
                    {{ $t('expert.phone_number') }}
                </span>
                <span v-else-if="props.column.field === 'isBlackList'" class="text-nowrap">
                    {{ $t('expert.is_black_list') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'isBlackList'">
                    <b-form-checkbox disabled v-model="props.row.isBlackList" text-field="name" class="demo-inline-spacing"/>
                </span>
                <span v-else-if="props.column.field === 'process'">
                  <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                      class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                      size="sm" @click="deleted(props.row)">
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </span>
                </span>
              </template>
    
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getExpertList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                pageLength: 10,
                dir: false,
                isBlackList: false,
                columns: [
                    {
                        label: this.$t('lawActivity.name'),
                        field: `name`,
                    },
                    {
                        label: this.$t('lawActivity.title'),
                        field: `title`,
                    },
                    {
                        label: this.$t('lawActivity.city'),
                        field: `city`,
                    },
                    {
                        label: this.$t('lawActivity.field_of_expertise'),
                        field: `fieldOfExpertise`,
                    },
                    {
                        label: this.$t('lawActivity.phone_number'),
                        field: `phoneNumber`,
                    },
                    {
                        label: this.$t('lawActivity.is_black_list'),
                        field: `isBlackList`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                searchTerm: '',
                total: 0,
                pageLength: 10,
                pageNo: 1,
            }
        },
        mounted() {
            this.getExpertList()
        },
        methods: {
            async deleted(data) {
                data = { id: data.id }
                const controller = { name: 'LawCourtExpert' }
                const payload = { data: data, controller: controller }
                this.$DeleteConfirm().then(result => {
                    if (result.value) {
                        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$DeleteAlert()
                                this.getExpertList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                this.getExpertList()
                            }
                        })
                    }
                })
            },
            async getInfo(id) {
                this.$refs.expertFormModal.showInfo(id)
            },
            getExpertList() {
                this.rows = []
                const data = { pageLength: this.pageLength, pageNo: this.pageNo}
                const controller = { name: 'LawCourtExpert' }
                const payload = { data: data, controller: controller }
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.lawCourtExpertList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
        },
    }
    </script>
      
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    [dir] .dark-layout .card {
        background-color: #252d3f !important;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
    }
    
    .custom-checkbox .custom-control-label,
    .custom-radio .custom-control-label {
        font-size: 1rem;
        position: static;
        inline-size: max-content !important;
    }
    </style>
    